<template>
	<div class="alert-box">
		<div class="main">
			<div class="login">
				<label for="chk" aria-hidden="true">Log In</label>
				<div class="v-margin20">
					<input class="m-auto" type="email" style="min-height: 48px;" v-model="form.email"
						placeholder="Email" required="">
				</div>
				<div class="v-margin20">
					<input class="m-auto" type="password" style="min-height: 48px;" v-model="form.password"
						placeholder="Password" required="">
				</div>
				<button class="button" v-on:click="login">Accedi</button>

			</div>
		</div>
		<v-dialog v-model="fail">
			<div class="send-mail-dialog">
				<p>{{ errorResponse }}</p>
				<v-card-actions><button class="button" v-on:click="fail = !fail">CHIUDI</button></v-card-actions>
			</div>
		</v-dialog>
	</div>
</template>


<script>
import axios from 'axios';
import store from '@/store/index.js';
import { logOut } from '../functions.js';

export default {
	name: 'AccessPage',
	data: () => ({
		form: {
			email: "",
			password: ""
		},
		ripeti_password: "",
		fail: false,
		errorResponse: ""
	}),
	computed: {
		index() {
			return store.getters.index;
		}
	},
	methods: {
		async login() {
			await axios.post('login', this.form)
				.then((res) => {
					if (res != undefined && res.data.status) {
						res.data.email = this.form.email;
						store.dispatch("setToken", res.data);
						if (res != undefined && res.data.role !== 'root' && res.data.role !== 'developer' && res.data.role !== 'editor' && res.data.role !== 'bot') {
							store.dispatch('contentAppDialog', "Non hai i permessi necessari per accedere.");
							store.dispatch('toggleAppDialog');
							logOut();
							return;
						}
						
						store.dispatch('setLogged', true);

						if (res != undefined && res.data.role === 'bot') {
							store.dispatch('setIndex', 16);
							return;
						}
						store.dispatch('getProductRequest');
						store.dispatch('getPackRequest');
						store.dispatch('getEventRequest');
						store.dispatch('getQuestCategoryRequest');
						store.dispatch('getQuestRequest');
						store.dispatch('getRewardRequest');
						store.dispatch('getItemRequest');
						store.dispatch('fetchAraInfos');
						store.dispatch('setIndex', 1);
					} else {
						this.errorResponse = res.data.data;
						this.fail = !this.fail;
					}
				});
		},
	}
}
</script>


<style>
.alert-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 140px;
	margin-bottom: 60px;
	font-family: 'Jost', sans-serif;
}

.main {
	width: 350px;
	border-radius: 20px;
	box-shadow: 0 15px 70px rgb(86, 193, 5);
	box-shadow: 0 15px 70px rgb(68, 211, 243);
}

label {
	color: #fff;
	font-size: 2.3em;
	justify-content: center;
	display: flex;
	font-weight: bold;
	cursor: pointer;
	transition: .5s ease-in-out;
}

input {
	position: relative;
	min-height: 20px;
	background: #e0dede;
	justify-content: center;
	display: flex;
	padding: 10px;
	border: none;
	outline: none;
	border-radius: 5px;
}

.button {
	width: 60%;
	height: 40px;
	margin: auto;
	justify-content: center;
	display: block;
	color: #fff;
	background: #10b73d;
	font-size: 1em;
	font-weight: bold;
	margin-top: 20px;
	outline: none;
	border: none;
	border-radius: 5px;
	transition: .2s ease-in;
	cursor: pointer;
}

.button:hover {
	background: #44878e;
}

.login {
	background: #eee;
	border-radius: 20px;
	padding: 30px;
}

.login label {
	color: #573b8a;
}
</style>