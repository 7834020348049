<template>
    <div v-html="htmlContent" style="margin: 20px 10px; text-align: left; color: bisque;">

    </div>
</template>
<script>
import axios from 'axios';
import { marked } from 'marked';
export default {
    name: "DocumentationView",
    data() {
        return {
            htmlContent: ''
        }
    },
    mounted() {
        this.fetchMarkdown();
    },
    methods: {
        async fetchMarkdown() {
            try {
                await axios.get('ara/documentation').then((res) => {
                    console.log(res);
                    this.htmlContent = marked(res.data);
                });
            } catch (error) {
                console.error('Error fetching the Markdown file:', error);
            }
        }
    }
}
</script>
<style></style>