<template>
    <div v-if="rewards && rewards.length > 0" id="reward-row">
        <div v-for="(reward, index) in rewards" :key="index" class="reward-box">
            <div v-if="edit" id="del-reward-btn">
                <v-icon size="33" @click="handleClick({index: index, id: reward.id})">mdi-close-circle</v-icon>
            </div>
            <span> <strong>ID: </strong></span>
            <span> {{ reward.id }} </span>
            <span> <strong>Punti: </strong></span>
            <span> {{ reward.points }} </span>
        </div>
    </div>
</template>
<script>
import store from '@/store/index'
export default {
    name: "EventRewardsList",
    data() {
        return {
        }
    },
    methods: {
        parseRewards() {
            this.list.split('|').forEach(element => {
                this.rewards.push(element.split(','));
            });
        },
        handleClick(payload) {
            this.$emit('reward-clicked', payload);
        },
    },
    computed: {
        rewards() {
            if(this.edit)
            {
                if(this.new)
                    return store.getters.eventRewards;
                else
                    return store.getters.updateEventRewards;
            } else {
                if(this.list === null || this.list === 0)
                    return [];
                var rew = [];
                this.list.split('|').forEach(element => {
                    rew.push({id: element.split(',')[0], points: element.split(',')[1]});
                });
                return rew;
            }
        },
        eventRewards() {
            return store.getters.eventRewards;
        }
    },
    props: { list: String, edit: Boolean, new: Boolean },
    mounted() {
    }
}
</script>
<style>
#reward-row {
    display: inline-block;
    flex-direction: row;
    max-width: 400px;
}

#del-reward-btn {
    position: absolute;
    right: -15px;
    top: -15px;
}
.reward-box {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    margin: 7px 7px;
    padding: 5px;
    text-align: flex-start;
    border: 1px solid black;
    border-radius: 7px;
    background-color: rgb(54, 171, 54);
}
</style>