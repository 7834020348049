<template>
	<div>FORNITURE</div>
</template>
<script>
export default {
	name: 'FornituresView',
	data: () => ({
		search: false,
		quantita: [15, 30, 50, 100],
		quantity: 15,
		cat_search: 9999,
		page: 1,
		pages: 0,
		order_creat: false,
		order_update: false,
		order_id: false,
		order_event_id: false,
		add: false,
		upd: false,
	}),
	computed: {
	},
	methods: {

	}
}
</script>