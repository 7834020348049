import Vue from 'vue'
import Vuex from 'vuex'
import questModule from './quest'
import eventModule from './events'
import questCategoryModule from './quest_category'
import productModule from './product'
import rewardModule from './reward'
import packModule from './pack'
import statsModule from './stats'
import itemModule from './items'
import araModule from './ara'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    questModule,
    eventModule,
    questCategoryModule,
    productModule,
    rewardModule,
    packModule,
    statsModule,
    itemModule,
    araModule
  },
  state: {
    index: 0,
    appDialog: false,
    contentAppDialog: "",
    role: "",
    email: "",
    token: "",
    codes: [],
    questions: [],
    birds: [],
    FW: [],
    filter_FW: [],
    checkpoint: [],
    rarities: [],
    filter_questions: [],
    filter_birds: [],
    checkpoint_limit: 0,
    roles: {
      root: "Root",
      developer: "Sviluppatore",
      editor: "Editor",
      bot: "Base"
    },
    emails: [],
    logged: false,
    snackbar: false,
    snackbar_text: '',
    response: false,
    selectedUnitTime: "Secondi",
    unitTimes: [
      "Secondi",
      "Minuti",
      "Ore",
      "Giorni",
      "Settimane"
    ],
    unitTimesIndex: {
      "Secondi": 0,
      "Minuti": 1,
      "Ore": 2,
      "Giorni": 3,
      "Settimane": 4
    },
    adsRewards: [],
  },
  getters: {
    dev() {
      return process.env.VUE_APP_BASE_URL.includes('dev');
    },
    index(state) {
      state.index = localStorage.getItem('birder_index')
      if (!state.index) {
        localStorage.setItem('birder_index', 0);
        state.index = localStorage.getItem('birder_index');
      }
      return state.index;
    },
    unitTimes(state) {
      return state.unitTimes;
    },
    unitTimesIndex(state) {
      return state.unitTimesIndex;
    },
    selectedUnitTime(state) {
      return state.selectedUnitTime;
    },
    appDialog(state) {
      return state.appDialog;
    },
    contentAppDialog(state) {
      return state.contentAppDialog;
    },
    token(state) {
      state.token = localStorage.getItem('birder_token');
      return state.token;
    },
    authHeader(state) {
      return "Bearer " + state.token;
    },
    birds(state) {
      return state.birds;
    },
    checkpoint_limit(state) {
      return state.checkpoint_limit;
    },
    filter_birds(state) {
      return state.filter_birds;
    },
    FW(state) {
      return state.FW;
    },
    checkpoint(state) {
      return state.checkpoint;
    },
    rarities(state) {
      return state.rarities;
    },
    filter_FW(state) {
      return state.filter_FW;
    },
    codes(state) {
      return state.codes;
    },
    questions(state) {
      return state.questions;
    },
    filter_questions(state) {
      return state.filter_questions;
    },
    email(state) {
      state.email = localStorage.getItem('birder_email');
      return state.email;
    },
    role(state) {
      state.role = localStorage.getItem('birder_role');
      return state.role;
    },
    roles(state) {
      return state.roles;
    },
    column(state) {
      return state.column;
    },
    logged(state) {
      return state.logged;
    },
    snackbar: state => state.snackbar,
    snackbarText(state) {
      return state.snackbar_text;
    },
    response(state) {
      return state.response;
    },
    emails(state) {
      return state.emails;
    },
    adsReward(state) {
      return state.adsRewards;
    }
  },
  mutations: {
    setIndex(state, payload) {
      localStorage.setItem('birder_index', payload);
      state.index = payload;
    },
    setSelectedUnitTime(state, payload) {
      state.selectedUnitTime = payload;
    },
    toggleAppDialog(state) {
      state.appDialog = !state.appDialog;
      if (!state.appDialog)
        state.contentAppDialog = "";
    },
    contentAppDialog(state, payload) {
      state.contentAppDialog = payload;
    },
    setToken(state, payload) {
      localStorage.setItem('birder_token', payload.token);
      localStorage.setItem('birder_role', payload.role);
      localStorage.setItem('birder_email', payload.email);
      state.token = payload.token;
      state.role = payload.role;
      state.email = payload.email;
    },
    setRarities(state, payload) {
      state.rarities = payload;
    },
    setEmails(state, payload) {
      state.emails = payload;
    },
    setCheckpointLimit(state, payload) {
      state.checkpoint_limit = payload;
    },
    setBirds(state, payload) {
      state.birds = payload;
    },
    setCheckpoint(state, payload) {
      state.checkpoint = payload;
    },
    setFilterBirds(state, payload) {
      state.filter_birds = payload;
    },
    setFW(state, payload) {
      state.FW = payload;
      state.filter_FW = payload;
    },
    setFilterFW(state, payload) {
      state.filter_FW = payload;
    },
    setCodes(state, payload) {
      state.codes = payload;
    },
    setQuestions(state, payload) {
      state.questions = payload;
      state.filter_questions = payload;
    },
    setFilterQuestions(state, payload) {
      state.filter_questions = payload;
    },
    logOut(state) {
      localStorage.removeItem("birder_token");
      localStorage.removeItem("birder_email");
      localStorage.removeItem("birder_role");
      localStorage.removeItem("birder_index");
      state.token = null;
      state.role = null;
      state.email = null;
      state.index = 0;
    },
    setLogged(state, payload) {
      state.logged = payload;
    },
    setResponse(state, payload) {
      state.response = payload;
    },
    setSnackbar(state, payload) {
      state.snackbar = payload;
    },
    setSnackbarText(state, payload) {
      state.snackbar_text = payload;
    },
    setAdsRewards(state, payload) {
      state.adsRewards = payload;
    }
  },
  actions: {
    toggleAppDialog({ commit }) {
      commit('toggleAppDialog');
    },
    setSelectedUnitTime({ commit }, payload) {
      commit('setSelectedUnitTime', payload);
    },
    contentAppDialog({ commit }, payload) {
      commit('contentAppDialog', payload);
    },
    setIndex({ commit }, payload) {
      commit('setIndex', payload);
    },
    setToken({ commit }, payload) {
      commit('setToken', payload);
    },
    logOut({ commit }) {
      commit('logOut');
    },
    setLogged({ commit }, payload) {
      commit('setLogged', payload);
    },
    setRarities({ commit }, payload) {
      commit('setRarities', payload);
    },
    setFW({ commit }, payload) {
      commit('setFW', payload);
    },
    setFilterFW({ commit }, payload) {
      commit('setFilterFW', payload);
    },
    setCheckpoint({ commit }, payload) {
      commit('setCheckpoint', payload);
    },
    setCodes({ commit }, payload) {
      commit('setCodes', payload);
    },
    setQuestions({ commit }, payload) {
      commit('setQuestions', payload);
    },
    setFilterQuestions({ commit }, payload) {
      commit('setFilterQuestions', payload);
    },
    setBirds({ commit }, payload) {
      commit('setBirds', payload);
    },
    setFilterBirds({ commit }, payload) {
      commit('setFilterBirds', payload);
    },
    setCheckpointLimit({ commit }, payload) {
      commit('setCheckpointLimit', payload);
    },

    showSnackbar({ commit }, payload) {
      const { error, text, status } = payload;
      commit('setResponse', error);
      commit('setSnackbarText', text);
      commit('setSnackbar', status);
    },
    setSnackbar({ commit }, payload) {
      commit('setSnackbar', payload);
    },
    setSnackbarText({ commit }, payload) {
      commit('setSnackbarText', payload);
    },
    setResponse({ commit }, payload) {
      commit('setResponse', payload);
    },
    setEmails({ commit }, payload) {
      commit('setEmails', payload);
    },
    setAdsRewards({ commit }, payload) {
      commit('setAdsRewards', payload);
    },
  }
})

export default store
