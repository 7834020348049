<template>
    <v-card class="flex quest-card" :style="{ backgroundColor: item.active ? '#b4f36d' : '#f77070' }"
        @click="handleClick">
        <v-card-text class="flex row">
            <div class="flex column gap40">
                <div class="event-row align-items">
                    <strong>{{ productTypes[item.type] }}</strong>
                    <v-icon class="special-icon" large>
                        {{ productTypeIcons[item.type] }}
                    </v-icon>
                </div>
                <div v-if="item.type === 1" class="flex row">
                    <strong>Livello:</strong> <v-spacer></v-spacer> {{ item.unlock_level }}
                </div>
                <div class="event-row align-items">
                    <strong>{{ productCurrency[item.currency] }}</strong>
                    <div class="currency-row">
                        <v-icon class="special-icon" large>
                            {{ productCurrencyIcons[item.currency] }}
                        </v-icon>
                        <strong v-if="item.currency !== 0 && item.currency !== 4">{{ item.amount }}</strong>
                    </div>
                </div>
                <div class="currency-row">
                    <strong>ID:</strong> {{ item.id ?? '0' }}
                </div>
                <span class="flex row">
                    <strong>Licenza PRO:</strong> {{ item.licence }}
                </span>
                <span class="flex row">
                    <v-icon large style="color: rgba(0,0,0,0.6); margin: auto;" v-if="item.notify === 1">mdi-bell</v-icon>
                    <v-icon large style="color: rgba(0,0,0,0.6); margin: auto;" v-else>mdi-bell-off</v-icon>
                </span>
            </div>
            <div class="flex column">
                <span class="flex row">
                    <strong>SKU:</strong> {{ item.sku }}
                </span>
                <span class="flex row">
                    <strong>ID row:</strong> {{ item.row_id }}
                </span>
                <span class="flex row">
                    <strong>Disponibile da:</strong> {{ item.available_from }}
                </span>
                <span class="flex row">
                    <strong>Disponibile in secondi:</strong> {{ item.seconds_to_open }}
                </span>
                <span class="flex row">
                    <strong>Disponibile fino a:</strong> {{ item.expire_at }}
                </span>
                <span class="flex row">
                    <strong>Pacchetti:</strong> {{ item.pack_id }}
                </span>
                <span class="flex row">
                    <strong>Uccelli:</strong> {{ item.birds }}
                </span>

                <span class="flex row">
                    <strong>Avatar:</strong> {{ item.avatar }}
                </span>
                <span class="flex row">
                    <strong>Bordo Avatar:</strong> {{ item.border }}
                </span>
                <span class="flex row">
                    <strong>Sfondo carta:</strong> {{ item.card }}
                </span>
                <span class="flex row">
                    <strong>Emoji:</strong> {{ item.emoji }}
                </span>
            </div>
            <div class="flex column">
                <span class="flex row">
                    <strong>Miglior foto:</strong> {{ item.better_photo }}
                </span>
                <span class="flex row">
                    <strong>Bird radar:</strong> {{ item.bird_radar }}
                </span>
                <span class="flex row">
                    <strong>Doppia foto:</strong> {{ item.double_photo }}
                </span>
                <span class="flex row">
                    <strong>Esposizione perfetta:</strong> {{ item.perfect_exposure }}
                </span>
                <span class="flex row">
                    <strong>Piume:</strong> {{ item.feathers }}
                </span>
                <span class="flex row">
                    <strong>Focus rapido:</strong> {{ item.rapid_focus }}
                </span>
                <span class="flex row">
                    <strong>Probabilità:</strong> {{ item.probability }}
                </span>
                <span class="flex row">
                    <strong>Monete:</strong> {{ item.coins }}
                </span>
                <span class="flex row">
                    <strong>Monete speciali:</strong> {{ item.special_coins }}
                </span>
                <span class="flex row">
                    <strong>Fortuna:</strong> {{ item.luck }}
                </span>
                <span class="flex row">
                    <strong>Punti VIP:</strong> {{ item.vip_points }}
                </span>
                <span class="flex row">
                    <strong>Valore:</strong> {{ item.value }}
                </span>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>

import store from '@/store/index.js'
export default {
    name: "ProductTile",
    data() {
        return {
        }
    },
    props: { item: Object },
    methods: {
        handleClick() {
            this.$emit('product-clicked');
        },
    },
    computed: {
        productTypes() {
            var types = [];
            store.getters.productTypes.forEach(element => {
                types.push(element.name);
            });
            return types;
        },
        productTypeIcons() {
            var icons = [];
            store.getters.productTypes.forEach(element => {
                icons.push(element.icon);
            });
            return icons;
        },
        productCurrency() {
            var types = [];
            store.getters.productCurrency.forEach(element => {
                types.push(element.name);
            });
            return types;
        },
        productCurrencyIcons() {
            var icons = [];
            store.getters.productCurrency.forEach(element => {
                icons.push(element.icon);
            });
            return icons;
        }
    }
}
</script>