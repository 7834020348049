import axios from 'axios';
import { deleteFromProduct } from '@/model_functions';

const productModule = {
  state: {
    products: [],
    filter_products: [],
    product: {
      'id': 0,
      'sku': 'offer_01 (a pagamento)',
      'active': 0,
      'type': 0,
      'unlock_level': 0,
      'expire_at': '2024-03-25 00:00:00',
      'available_from': '2024-01-25 00:00:00',
      'seconds_to_open': 0,
      'pack_id': 'nome_pacchetto',
      'coins': 0,
      'special_coins': 0,
      'feathers': 0,
      'vip_points': 0,
      'better_photo': 0,
      'rapid_focus': 0,
      'probability': 0,
      'bird_radar': 0,
      'luck': 0,
      'perfect_exposure': 0,
      'double_photo': 0,
      'birds': 0,
      'currency': 0,
      'amount': 0,
      'row_id': 'row_id',
      'avatar': 0,
      'border': 0,
      'card': 0,
      'emoji': 0,
      'licence': 0,
      'notify': 0
    },
    updateProduct: {},
    chosenProductCurrency: '',
    chosenProductType: '',
    updateChosenProductType: '',
    updateChosenProductCurrency: '',
    prod_filter: {
      id_filter: '',
      pack_id_filter: '',
      row_id_filter: '',
      SKU_filter: '',
      active_filter: false,
    },
    productTypes: [
      {
        name: "Fisso",
        icon: "mdi-lock"
      },
      {
        name: "A livello",
        icon: "mdi-account-arrow-up",
      },
      {
        name: "Ad evento",
        icon: "mdi-calendar",
      },
      {
        name: "Speciale",
        icon: "mdi-star",
      },
      {
        name: "Campionato",
        icon: "mdi-trophy",
      },
      {
        name: "Giornaliero",
        icon: "mdi-sun-clock",
      },
      {
        name: "Duello",
        icon: "mdi-fencing",
      },
      {
        name: "Bentornato",
        icon: "mdi-human-greeting"
      },
      {
        name: "A tempo",
        icon: "mdi-timer-sand"
      },
    ],
    productCurrencies: [
      {
        name: "Gratis",
        icon: "mdi-cash-off",
      },
      {
        name: "Monete",
        icon: "mdi-hand-coin",
      },
      {
        name: "Piume",
        icon: "mdi-feather",
      },
      {
        name: "Moneta speciale",
        icon: "mdi-star",
      },
      {
        name: "Euro",
        icon: "mdi-currency-eur"
      }
    ],
    productTypesIndex: {
      "Fisso": 0,
      "A livello": 1,
      "Ad evento": 2,
      "Speciale": 3,
      "Campionato": 4,
      "Giornaliero": 5,
      "Duello": 6,
      "Bentornato": 7,
      "A tempo": 8
    },
    productCurrencyIndex: {
      "Gratis": 0,
      "Monete": 1,
      "Piume": 2,
      "Moneta speciale": 3,
      "Euro": 4
    },
  },
  getters: {
    products(state) {
      return state.products;
    },
    filter_products(state) {
      return state.filter_products;
    },
    product(state) {
      return state.product;
    },
    updateProduct(state) {
      return state.updateProduct;
    },
    chosenProductCurrency(state) {
      return state.chosenProductCurrency;
    },
    chosenProductType(state) {
      return state.chosenProductType;
    },
    updateChosenProductType(state) {
      return state.updateChosenProductType;
    },
    updateChosenProductCurrency(state) {
      return state.updateChosenProductCurrency;
    },
    productTypes(state) {
      return state.productTypes;
    },
    productCurrency(state) {
      return state.productCurrencies;
    },
    productTypesIndex(state) {
      return state.productTypesIndex;
    },
    productCurrencyIndex(state) {
      return state.productCurrencyIndex;
    },
    getIDfilter(state) {
      return state.prod_filter.id_filter;
    },
    getPackfilter(state) {
      return state.prod_filter.pack_id_filter;
    },
    getRowIDfilter(state) {
      return state.prod_filter.row_id_filter;
    },
    getSKUfilter(state) {
      return state.prod_filter.SKU_filter;
    },
    getActivefilter(state) {
      return state.prod_filter.active_filter;
    }
  },
  mutations: {
    setProducts(state, payload) {
      state.products = payload;
      state.filter_products = state.products.filter(product => {
        const idProduct = product.id.toString().includes(state.prod_filter.id_filter ?? '');
        const packIdProduct = (product.pack_id ?? '').includes(state.prod_filter.pack_id_filter ?? '');
        const rowIdProduct = product.row_id.toString().includes(state.prod_filter.row_id_filter ?? '');
        const skuProduct = (product.sku ?? '').includes(state.prod_filter.SKU_filter ?? '');

        let activeProduct;
        if (state.prod_filter.active_filter) {
          activeProduct = product.active == 1;
        } else {
          activeProduct = product.active == 1 || product.active == 0;
        }

        return idProduct && packIdProduct && rowIdProduct && skuProduct && activeProduct;
      });
    },
    setFilterProducts(state, payload) {
      state.filter_products = payload;
    },
    setProduct(state, payload) {
      state.product = payload;
    },
    setFilter(state, { filterName, value }) {
      switch (filterName) {
        case 0:
          state.prod_filter.id_filter = value;
          break;
        case 1:
          state.prod_filter.pack_id_filter = value;
          break;
        case 2:
          state.prod_filter.row_id_filter = value;
          break;
        case 3:
          state.prod_filter.sku_filter = value;
          break;
        case 4:
          state.prod_filter.active_filter = value;
          break;

        default:
          break;
      }
    },
    setUpdateProduct(state, payload) {
      state.updateProduct = payload;
      var isNull = false;
      if (payload.currency == undefined)
        isNull = true;
      state.updateChosenProductCurrency = isNull ? '' : state.productCurrencies[payload.currency].name;
      state.updateChosenProductType = isNull ? '' : state.productTypes[payload.type].name;
    },
    setChosenProductType(state, payload) {
      state.chosenProductType = payload;
    },
    setUpdateChosenProductType(state, payload) {
      state.updateChosenProductType = payload;
    },
    setChosenProductCurrency(state, payload) {
      state.chosenProductCurrency = payload;
    },
    setUpdateChosenProductCurrency(state, payload) {
      state.updateChosenProductCurrency = payload;
    },
    filterList(state) {
      state.filter_products = state.products.filter(product => {
        const idMatch = product.id.toString().includes(state.prod_filter.id_filter ?? '');
        const packIdMatch = (product.pack_id ?? '').includes(state.prod_filter.pack_id_filter ?? '');
        const rowIdMatch = product.row_id.toString().includes(state.prod_filter.row_id_filter ?? '');
        const skuMatch = (product.sku ?? '').includes(state.prod_filter.sku_filter ?? '');

        let activeMatch;
        if (state.prod_filter.active_filter) {
          activeMatch = product.active === 1;
        } else {
          activeMatch = product.active === 1 || product.active === 0;
        }

        return idMatch && packIdMatch && rowIdMatch && skuMatch && activeMatch;
      });
    }
  },
  actions: {
    setProducts({ commit }, payload) {
      commit('setProducts', payload);
    },
    setFilterProducts({ commit }, payload) {
      commit('setFilterProducts', payload);
    },
    setProduct({ commit }, payload) {
      commit('setProduct', payload);
    },
    setUpdateProduct({ commit }, payload) {
      commit('setUpdateProduct', payload);
    },
    setChosenProductCurrency({ commit }, payload) {
      commit('setChosenProductCurrency', payload);
    },
    filterList({ commit }) {
      commit('filterList');
    },
    setChosenProductType({ commit }, payload) {
      commit('setChosenProductType', payload);
    },
    setUpdateChosenProductCurrency({ commit }, payload) {
      commit('setUpdateChosenProductCurrency', payload);
    },
    setUpdateChosenProductType({ commit }, payload) {
      commit('setUpdateChosenProductType', payload);
    },
    setIdfilter({ commit }, payload) {
      commit('setFilter', { filterName: 0, value: payload });
    },
    setPackfilter({ commit }, payload) {
      commit('setFilter', { filterName: 1, value: payload });
    },
    setRowIdfilter({ commit }, payload) {
      commit('setFilter', { filterName: 2, value: payload });
    },
    setSKUfilter({ commit }, payload) {
      commit('setFilter', { filterName: 3, value: payload });
    },
    setActivefilter({ commit }, payload) {
      commit('setFilter', { filterName: 4, value: payload });
    },
    async getProductRequest({ commit }) {
      axios
        .get('products/get')
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setProducts", res.data.data);
          } else {
            commit("logOut");
            commit('setLogged', false);
          }
        })
        .catch(() => {
          commit("logOut");
          commit('setLogged', false);
        });
    },
    async updateProductRequest({ commit, state }, callback) {
      const q = state.updateProduct;
      q.currency = state.productCurrencyIndex[state.updateChosenProductCurrency];
      q.type = state.productTypesIndex[state.updateChosenProductType];
      await axios
        .put('products/update/' + q.id, q)
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setProducts", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Modificata missione ID: " + q.id);
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
        });
    },
    async duplicateProductRequest({ commit, state }, callback) {
      const q = state.updateProduct;
      q.currency = state.productCurrencyIndex[state.updateChosenProductCurrency];
      q.type = state.productTypesIndex[state.updateChosenProductType];
      await axios
        .post('products/create/', q)
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setProducts", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Creata nuova missione");
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
        });
    },
    async createProductRequest({ commit, state }, callback) {
      const q = state.product;
      q.currency = state.productCurrencyIndex[state.chosenProductCurrency];
      q.type = state.productTypesIndex[state.chosenProductType];
      await axios
        .post('products/create/', q)
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setProducts", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Creata nuova missione");
            commit('setSnackbar', true);
            commit('setProduct', {
              'id': 0,
              'sku': 'offer_01 (a pagamento)',
              'active': 0,
              'type': 0,
              'unlock_level': 0,
              'expire_at': '2024-03-25 00:00:00',
              'available_from': '2024-01-25 00:00:00',
              'seconds_to_open': 0,
              'pack_id': 'nome_pacchetto',
              'coins': 0,
              'special_coins': 0,
              'feathers': 0,
              'vip_points': 0,
              'better_photo': 0,
              'rapid_focus': 0,
              'probability': 0,
              'bird_radar': 0,
              'luck': 0,
              'perfect_exposure': 0,
              'double_photo': 0,
              'birds': 0,
              'currency': 0,
              'amount': 0,
              'row_id': 'row_id',
              'avatar': 0,
              'border': 0,
              'card': 0,
              'emoji': 0,
              'licence': 0,
              'notify': 0
            });
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
        });
    },
    async deleteProductRequest({ commit, state }, callback) {
      const q = state.updateProduct;
      await axios
        .delete('products/delete/' + q.id)
        .then(() => {
          deleteFromProduct(q.id);
          commit('setResponse', true);
          commit('setSnackbarText', "Eliminate missione ID: " + q.id);
          commit('setSnackbar', true);
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
        });
    }
  },
};

export default productModule;
