<template>
	<div class="quest-body">
		<div class="flex column">
			<section v-if="!dialog" class="code-column">
				<header>
					<h1>Aggiungi codice</h1>
				</header>
				<v-card class="flex code-card">
					<v-card-text>
						<span>
							<strong>Codice:</strong> <v-spacer></v-spacer> <input v-model="code.code" />
						</span>
					</v-card-text>
					<v-card-text>
						<span>
							<strong>ID prodotto:</strong> <v-spacer></v-spacer> <input v-model="code.reward_id" />
						</span>
					</v-card-text>
					<v-card-text>
						<span>
							<strong>Data:</strong> <v-spacer></v-spacer> <input v-model="code.expire" />
						</span>
					</v-card-text>
				</v-card>
				<button class="new-codes-btn" v-on:click="createcodes">CONFERMA</button>
			</section>
			<section v-if="dialog" class="code-column">
				<header>
					<div class="flex row v-margin10">
						<h1>Elimina codice</h1>
						<v-spacer></v-spacer>
						<button class="new-codes-btn" v-on:click="dialog = !dialog">ANNULLA</button>
					</div>
				</header>
				<v-card class="flex code-card">
					<v-card-text>
						<span>
							<strong>Codice:</strong> {{ code.code }}
						</span>
					</v-card-text>
					<v-card-text>
						<span>
							<strong>ID prodotto:</strong> {{ code.reward_id }}
						</span>
					</v-card-text>
					<v-card-text>
						<span>
							<strong>Data:</strong> {{ code.expire }}
						</span>
					</v-card-text>
				</v-card>
				<button class="new-codes-btn" v-on:click="deletecodes(update_code.id)">ELIMINA</button>
			</section>
		</div>
		<div id="sider">
			<section class="code-column">
				<header class="title-icon">
					<v-icon large>mdi-barcode</v-icon>
					<h1>Codici</h1>
				</header>
				<span v-if="codes.length === 0" style="color: antiquewhite;">0 Codici</span>
				<div v-else>
					<v-card v-for="( code, index ) in codes" :key="index" class="flex code-card" @click="vai(index)">
						<v-card-text>
							<span>
								<strong>Codice:</strong> {{ code.code }}
							</span>
						</v-card-text>
						<v-card-text>
							<span>
								<strong>ID prodotto:</strong> {{ code.reward_id }}
							</span>
						</v-card-text>
						<v-card-text>
							<span>
								<strong>Data:</strong> {{ code.expire }}
							</span>
						</v-card-text>
					</v-card>
				</div>
			</section>
		</div>
	</div>
</template>


<script>
import axios from 'axios';
import store from '@/store/index.js'
import { getCodes } from '../functions.js';


export default {
	name: 'CodeView',
	data: () => ({
		code: {
			"code": 0,
			"reward_id": 0,
			"expire": 0,
		},
		update_code: {},
		dialog: false
	}),
	computed: {
		token() {
			return store.getters.token;
		},
		codes() {
			return store.getters.codes;
		},
	},
	methods: {
		vai(index) {
			this.update_code = Object.assign({}, this.codes[index]);
			this.dialog = true;
		},
		async createcodes() {
			await axios
				.post('codes/create/', this.code)
				.then(() => {
					getCodes();
				});
		},
		async deletecodes() {
			await axios
				.delete('codes/delete/' + this.update_code.code)
				.then((res) => {
					if (res != undefined && res.data.status) {
						this.dialog = false;
						getCodes();
					}
				});
		}
	},
	async mounted() {
		if (this.token === null)
			store.dispatch('setIndex', 0);
		else
			await getCodes();
	}
}
</script>


<style>
.code-body {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin: 20px auto;
	gap: 10px;
	overflow-x: scroll;
}

.code-column {
	border-radius: 20px;
	box-shadow: 0 5px 15px rgb(86, 193, 5);
	margin-top: 20px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 20px;
	padding: 10px;
}

.code-body h1 {
	font-family: 'Cairo';
	font-weight: 900;
	color: bisque;
	text-align: flex-start;
}

.code-card {
	margin: 5px;
	border: solid 3px;
	align-items: center;
}

.code-card .row {
	margin: 10px;
}

.code-card strong {
	margin-right: 13px;
}

.code-card input {
	max-width: 65px;
	min-width: 130px;
}

.code-body input {
	min-height: 30px;
	margin: 0 auto;
}

.title-icon {
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: 1rem;
}

.title-icon h1 {
	width: fit-content;
}


.table-header {
	background-color: antiquewhite;
	box-shadow: 0 3px 5px black;
}

.table-header th {
	padding: 2px;
}

.header-row {
	display: flex;
	flex-direction: row;
}

.div-tile {
	display: flex;
	flex-direction: row;
	margin: 2px;
}

.table-tile {
	background-color: cadetblue;
}

.new-codes-btn {
	width: 150px;
	height: 50px;
	background-color: antiquewhite;
	border-radius: 20px;
	border: 2px solid black;
}
</style>