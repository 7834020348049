<template>
    <section style="margin-top: 30px;">
        <header>
            <label>
                {{ this.lista[this.scenery].nome }}
            </label>
        </header>
        <div class="flex row">

            <table class="bird-table">
                <tr>
                    <th style="width: 50px;">ID</th>
                    <th style="width: 300px;">Nome</th>
                    <th style="width: 120px;">Rarita</th>
                    <th v-for="level in levels" :key="level" style="width: 50px;">{{ level }}</th>
                    <th style="width: 80px;">Media</th>
                </tr>
                <tr v-for="(birdData, birdId) in listFixed" :key="birdId"
                    :style="{ backgroundColor: colors[birdData.rarity] }">
                    <td>{{ birdId }}</td>
                    <td style="width: 300px;">{{ birdData.nome }}</td>
                    <td>{{ rarity(birdData.rarity) }}</td>
                    <td v-for="level in levels" :key="level">
                        {{ birdData[level] || 0 }} <!-- Display count or 0 if count is undefined -->
                    </td>
                    <td>{{ birdData.media_singolo }}</td>
                </tr>
            </table>
            <div class="column min-width align-items m-auto">
                <div class="flex column m-auto minW300">
                    <span style="color: bisque; font-weight: 600; font-size: 20px;">Media comuni</span>
                    <p style="color: bisque;"><strong>{{medie['Comune'].media}} </strong></p>
                </div>

                <div class="flex column m-auto minW300">
                    <span style="color: bisque; font-weight: 600; font-size: 20px;">Media rari</span>
                    <p style="color: bisque;"><strong>{{medie['Raro'].media}} </strong></p>
                </div>

                <div class="flex column m-auto minW300">
                    <span style="color: bisque; font-weight: 600; font-size: 20px;">Media epici</span>
                    <p style="color: bisque;"><strong>{{medie['Epico'].media}} </strong></p>
                </div>

                <div class="flex column m-auto minW300">
                    <span style="color: bisque; font-weight: 600; font-size: 20px;">Media mitici</span>
                    <p style="color: bisque;"><strong>{{medie['Mitico'].media}} </strong></p>
                </div>

                <div class="flex column m-auto minW300">
                    <span style="color: bisque; font-weight: 600; font-size: 20px;">Media leggendario</span>
                    <p style="color: bisque;"><strong>{{medie['Leggendario'].media}} </strong></p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from "axios";
import store from "@/store";
export default {
    name: 'SceneryDetails',
    props: ['scenery'],
    data() {
        return {
            levels: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            lista: [
                {
                    nome: 'Delta del Po',
                    scenery_id: 10001
                },
                {
                    nome: 'Minsmere',
                    scenery_id: 10002
                },
                {
                    nome: 'Everglades',
                    scenery_id: 10003
                },
                {
                    nome: 'Kruger',
                    scenery_id: 10004
                },
                {
                    nome: 'Hawaii',
                    scenery_id: 10005
                },
                {
                    nome: 'Manu',
                    scenery_id: 10006
                },
            ],
            medie: {
                Comune: {
                    count: 0,
                    media: 0

                },
                Raro: {
                    count: 0,
                    media: 0

                },
                Epico: {
                    count: 0,
                    media: 0

                },
                Mitico: {
                    count: 0,
                    media: 0

                },
                Leggendario: {
                    count: 0,
                    media: 0

                },
            },
            colors: {
                0: "#524E4E",
                1: "#3C79B2",
                2: "#26862A",
                3: "#622686",
                4: "#9C7233"
            },
            listFixed: [],
        }
    },
    computed: {
        deltaBirds() {
            return store.getters.deltaBirds;
        },
        minsmeBirds() {
            return store.getters.minsmeBirds;
        },
        everBirds() {
            return store.getters.everBirds;
        },
        krugerBirds() {
            return store.getters.krugerBirds;
        },
        hawaiiBirds() {
            return store.getters.hawaiiBirds;
        },
        manuBirds() {
            return store.getters.manuBirds;
        },
        currentList() {
            switch (this.scenery) {
                case 0:
                    return this.deltaBirds;
                case 1:
                    return this.minsmeBirds;
                case 2:
                    return this.everBirds;
                case 3:
                    return this.krugerBirds;
                case 4:
                    return this.hawaiiBirds;
                case 5:
                    return this.manuBirds;
                default:
                    return [];
            }
        },
    },
    methods: {
        rarity(valore) {
            switch (valore) {
                case 0:
                    return "Comune";
                case 1:
                    return "Raro";
                case 2:
                    return "Epico";
                case 3:
                    return "Mitico";
                case 4:
                    return "Leggendario";
                default:
                    return "N.A.";
            }
        },
        getInfo() {
            if (this.currentList.length > 0) {
                this.setInfo();
                return;
            }
            axios.get('editor/stats/birds/' + this.lista[this.scenery].scenery_id)
                .then((res) => {
                    if (res != undefined && res.data.status) {
                        store.dispatch('setBirds', { scenery: this.lista[this.scenery].scenery_id, birds: res.data.data });
                        this.setInfo();
                    } else {
                        store.dispatch('setResponse', false);
                        store.dispatch('setSnackbarText', res.data.data);
                        store.dispatch('setSnackbar', true);
                    }
                }).catch((error) => {
                    store.dispatch('setResponse', false);
                    store.dispatch('setSnackbarText', error.response.data.data ?? error);
                    store.dispatch('setSnackbar', true);
                });
        },
        setInfo() {
            this.listFixed = {};
            this.currentList.forEach(element => {
                if (!this.listFixed[element.bird_id])
                    this.listFixed[element.bird_id] = {};
                if (!this.listFixed[element.bird_id]['nome'])
                    this.listFixed[element.bird_id]['nome'] = element.name;
                if (!this.listFixed[element.bird_id]['rarity'])
                    this.listFixed[element.bird_id]['rarity'] = element.rarity;
                if (!this.listFixed[element.bird_id][element.level])
                    this.listFixed[element.bird_id][element.level] = 1;
                else
                    this.listFixed[element.bird_id][element.level] += 1;

                if (!this.listFixed[element.bird_id]['media_singolo'])
                    this.listFixed[element.bird_id]['media_singolo'] = element.level;
                else
                    this.listFixed[element.bird_id]['media_singolo'] += element.level;

                if (!this.listFixed[element.bird_id]['count'])
                    this.listFixed[element.bird_id]['count'] = 1;
                else
                    this.listFixed[element.bird_id]['count'] += 1;
            });

            for (const birdId in this.listFixed) {
                this.listFixed[birdId]['media_singolo'] = (this.listFixed[birdId]['media_singolo'] / this.listFixed[birdId]['count']).toFixed(2);
                this.medie[this.rarity(this.listFixed[birdId]['rarity'])].media += parseFloat(this.listFixed[birdId]['media_singolo']);
                this.medie[this.rarity(this.listFixed[birdId]['rarity'])].count += 1;
            }

            for (const rarity in this.medie) {
                this.medie[rarity].media = (this.medie[rarity].media / this.medie[rarity].count).toFixed(2);
            }
        }
    },
    mounted() {
        this.getInfo();
    },
}
</script>
<style>
.bird-table {
    color: white;
    height: fit-content;
    margin: 20px;
}

.bird-table th {
    background-color: var(--secondary-color);
    border: 1px solid grey;
}

.bird-table td {
    border: 1px solid grey;
}
</style>