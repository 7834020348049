<template>
    <v-card prepend-icon="mdi-map-marker">
        <v-card-text class="flex row space-around">
            <v-date-picker v-model="date" first-day-of-week="1"></v-date-picker>
            <v-time-picker format="24hr" v-model="time"></v-time-picker>
            <p style="font-size: 20px">
                <strong>{{ date + " " + time }}</strong>
            </p>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn @click="$emit('cancel-date')">Annulla</v-btn>

            <v-btn @click="$emit('confirmed-date', { tag: tag, date: date + ' ' + time })">
                Conferma
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    name: "DateTimePicker",
    data() {
        return {
            show: false,
            date: '2024-12-1',
            time: '00:00:00'
        }
    },
    props: {
        tag: { type: String, required: true },
        initialDate: { type: String, required: false }
    },
    mounted() {
        if (this.initialDate !== null && this.initialDate !== undefined && this.initialDate.length > 0) {
            this.date = this.initialDate.split(' ')[0];
            this.time = this.initialDate.split(' ')[1];
        }
    }
}
</script>