<template>
    <div class="v-margin20 h-margin-auto">
        <label>
            Reward dedicate alle pubblicita
        </label>

        <div class="flex row">
            <div class="form-reward-container">
                <div class="flex column">

                    <label class="fit-content posix-label">Comune ({{rewards['base'].length}})</label>
                    <div class="flex row">
                        <input class="position-input posix" v-model="base">
                        <v-icon large class="btn-in-row" v-on:click="setReward('base')">mdi-plus</v-icon>
                        <v-card class="adv-reward-card" style="
                        background-color: var(--secondary-color);" v-for="(reward, index) in rewards['base']" :key="index">
                            <p>
                                {{reward}}
                            </p>
                            <div class="flex row">
                                <v-icon medium class="btn-in-row"
                                    v-on:click="delReward('base', reward)">mdi-delete</v-icon>

                                <v-icon medium class="btn-in-row"
                                    v-on:click="showReward(reward)">mdi-eye-outline</v-icon>
                            </div>
                        </v-card>
                    </div>
                </div>
                <div class="flex column">

                    <label class="fit-content posix-label">Pioggia dorata ({{rewards['gold'].length}})</label>
                    <div class="flex row">
                        <input class="position-input posix" v-model="gold">
                        <v-icon large class="btn-in-row" v-on:click="setReward('gold')">mdi-plus</v-icon>
                        <v-card class="adv-reward-card" style="
                        background-color: var(--secondary-color);" v-for="(reward, index) in rewards['gold']" :key="index">
                            <p>
                                {{reward}}
                            </p>
                            <div class="flex row">
                                <v-icon medium class="btn-in-row"
                                    v-on:click="delReward('gold', reward)">mdi-delete</v-icon>

                                <v-icon medium class="btn-in-row"
                                    v-on:click="showReward(reward)">mdi-eye-outline</v-icon>
                            </div>
                        </v-card>
                    </div>
                </div>
                <div class="flex column">
                    <label class="fit-content posix-label">Settimana completa ({{rewards['deluxe'].length}})</label>
                    <div class="flex row">
                        <input class="position-input posix" v-model="deluxe">
                        <v-icon large class="btn-in-row" v-on:click="setReward('deluxe')">mdi-plus</v-icon>
                        <v-card class="adv-reward-card" style="
                        background-color: var(--secondary-color);" v-for="(reward, index) in rewards['deluxe']" :key="index">
                            <p>
                                {{reward}}
                            </p>
                            <div class="flex row">
                                <v-icon medium class="btn-in-row"
                                    v-on:click="delReward('deluxe', reward)">mdi-delete</v-icon>

                                <v-icon medium class="btn-in-row"
                                    v-on:click="showReward(reward)">mdi-eye-outline</v-icon>
                            </div>
                        </v-card>
                    </div>
                </div>
            </div>
            <div class="show-sample-reward">
                <RewardTile v-if="getShow" :item="reward"></RewardTile>
            </div>
        </div>
        <RewardsView style="margin-top: 30px;"></RewardsView>
    </div>
</template>
<script>
    import axios from "axios";
    import store from "@/store";
    import RewardsView from "@/views/Reward/RewardsView.vue";
    import RewardTile from "@/views/Reward/RewardTile.vue";

    export default {
        name: "AdsPage",
        data() {
            return {
                rewards: {
                    base: [],
                    gold: [],
                    deluxe: []
                },
                show: false,
                index: 99,
                reward: {},
                base: '',
                gold: '',
                deluxe: '',
                clicks: 0,
                timer: null,
                delay: 300,
            };
        },
        methods: {
            resetFields() {
                this.base = '';
                this.gold = '';
                this.deluxe = '';
            },
            async getReward() {
                await axios
                    .get('adv/rewards/get')
                    .then((res) => {
                        if (res != undefined && res.data.status) {
                            this.rewards = res.data.data;
                        }
                        else {
                            store.dispatch('setResponse', false);
                            store.dispatch('setSnackbarText', res.data.data ?? "Errore");
                            store.dispatch('setSnackbar', true);
                        }
                    })
                    .catch((error) => {
                        store.dispatch('setResponse', false);
                        store.dispatch('setSnackbarText', error.response.data.data ?? "Errore: " + error);
                        store.dispatch('setSnackbar', true);
                    });
            },
            async setReward(payload) {
                var data = null;
                switch (payload) {
                    case 'base':
                        data = { 'base': this.base };
                        break;
                    case 'gold':
                        data = { 'gold': this.gold };
                        break;
                    case 'deluxe':
                        data = { 'deluxe': this.deluxe };
                        break;
                    default:
                        return;
                }
                await axios
                    .post('adv/rewards/set', data)
                    .then((res) => {
                        if (res != undefined && res.data.status) {
                            this.resetFields();
                            this.rewards = res.data.data;
                            store.dispatch('setResponse', true);
                            store.dispatch('setSnackbarText', "Aggiunta ricompensa pubblicita");
                        }
                        else {
                            store.dispatch('setResponse', false);
                            store.dispatch('setSnackbarText', res.data.data ?? "Errore");
                        }
                        store.dispatch('setSnackbar', true);
                    })
                    .catch((error) => {
                        store.dispatch('setResponse', false);
                        store.dispatch('setSnackbarText', error.response.data.data ?? "Errore: " + error);
                        store.dispatch('setSnackbar', true);
                    });
            },
            async delReward(payload, rewarding) {
                this.clicks++;

                if (this.clicks === 1) {
                    this.timer = setTimeout(() => {
                        this.clicks = 0;
                        store.dispatch('setResponse', false);
                        store.dispatch('setSnackbarText', "Doppio click per eliminare.");
                        store.dispatch('setSnackbar', true);
                    }, this.delay);
                    return;
                }
                this.clicks = 0;
                store.dispatch('setResponse', true);
                store.dispatch('setSnackbarText', "");
                store.dispatch('setSnackbar', false);

                var data = null;
                switch (payload) {
                    case 'base':
                        data = { 'base': rewarding };
                        break;
                    case 'gold':
                        data = { 'gold': rewarding };
                        break;
                    case 'deluxe':
                        data = { 'deluxe': rewarding };
                        break;
                    default:
                        return;
                }
                await axios
                    .post('adv/rewards/delete', data)
                    .then((res) => {
                        if (res != undefined && res.data.status) {
                            this.resetFields();
                            this.rewards = res.data.data;
                            store.dispatch('setResponse', true);
                            store.dispatch('setSnackbarText', "Eliminata ricompensa pubblicita");
                        }
                        else {
                            store.dispatch('setResponse', false);
                            store.dispatch('setSnackbarText', res.data.data ?? "Errore");
                        }
                        store.dispatch('setSnackbar', true);
                    })
                    .catch((error) => {
                        store.dispatch('setResponse', false);
                        store.dispatch('setSnackbarText', error.response.data.data ?? "Errore: " + error);
                        store.dispatch('setSnackbar', true);
                    });
            },
            showReward(index) {
                if (index === this.index) {
                    this.show = false;
                    this.index = 99;
                    this.reward = {};
                    return;
                }

                this.index = index;
                store.getters.rewards.forEach(element => {
                    if (element.id.toString() === this.index) {
                        this.reward = element;
                    }
                });
                this.show = true;
            },
        },
        computed: {
            getIndex() {
                return this.index;
            },
            getShow() {
                return this.show;
            }
        },
        mounted() {
            this.getReward();
        },
        components: { RewardsView, RewardTile }
    }
</script>
<style>
    .posix {
        height: fit-content;
    }

    .position-input {
        width: fit-content;
        margin: 5px;
    }

    .posix-label {
        color: bisque;
        font-size: 20px;
        margin-bottom: 10px;
        margin-top: 15px;
    }

    .btn-in-row {
        margin: 5px;
    }

    .form-reward-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 40%;
        height: fit-content;
        margin-left: 30px;
        gap: 20px;

    }

    .adv-reward-card {
        display: flex;
        flex-direction: column;
        margin: 3px;
        min-height: 80px;
        text-align: center;
    }

    .adv-reward-card * {
        margin: 0px 7px;
        padding: 0;
    }

    .show-sample-reward {
        width: fit-content;
        margin: 30px auto;
    }
</style>