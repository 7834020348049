import axios from 'axios';

const araModule = {
  state: {
    skeepLimit: 0,
  },
  getters: {
    skeepLimit(state) {
      return state.skeepLimit;
    }
  },
  mutations: {
    setSkeepLimit(state, payload) {
      state.skeepLimit = payload;
    },

  },
  actions: {
    async fetchAraInfos({ commit }) {
      axios
        .get('ara/editor/get')
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setSkeepLimit", res.data.data.limit);
          }
        })
        .catch(() => {
        });
    },
    async updateSkeepLimit({ commit }, payload) {
      const { limit } = payload;
      axios
        .post('ara/editor/daily/limit/set', { "limit": limit })
        .then((res) => {
          if (res != undefined && res.status == 204) {
            commit("setSkeepLimit", limit);
          }
        })
        .catch(() => {
        });
    }
  }
};

export default araModule;
