<template>
	<div>
		<section v-if="FW.length < 12" class="fwr-column">
			<header>
				<h1>Aggiungi reward ruota fortuna</h1>
			</header>
			<v-card class="new-product-card"
				:style="{ backgroundColor: fwr.active ? '#b4f36d' : '#f77070', borderColor: '#222222' }">
				<v-card-text class="flex row">
					<div class="new-fwr-icon">
						<v-select v-model="chosen_rarity" solo :items="rarities_name">
						</v-select>
						<div class="flex column">
							<input type="checkbox" class="checkbox" v-model="fwr.active" />
							<strong style="margin: auto;">{{ fwr.active ? 'Attivo' : 'Disattivo' }}</strong>
						</div>
					</div>
					<div class="flex column">
						<ul class="event-list">
							<li>
								<div class="flex row">
									<strong>ID:</strong> <v-spacer></v-spacer> {{ fwr.id }}
								</div>
							</li>
							<li>
								<div class="flex row">
									<strong>Reward ID:</strong> <v-spacer></v-spacer> <input v-model="fwr.reward_id" />
								</div>
							</li>
						</ul>
					</div>
				</v-card-text>
			</v-card>

			<div class="row  v-margin10 space-around">
				<button class="new-event-btn" v-on:click="createFW">CONFERMA</button>
			</div>
		</section>
		<div class="flex row">
			<section class="fwr-column">
				<header class="title-icon">
				<v-icon large >mdi-gift</v-icon>
					<h1>Reward Ruota fortuna</h1>
				</header>
				<div class="pages-index">
					<span v-for="num in pages" :key="num" v-on:click="changePage(num)"
						:style="{ color: page === num ? '#b4f36d' : '#faebd7' }">{{ num }}</span>
					<span>{{ FW.length }}/12</span>
				</div>
				<v-card v-for="( fwr, index ) in FW.slice(((current_page - 1) * quantity), (current_page * quantity))"
					:key="index" class="flex row fwr-card"
					:style="{ backgroundColor: fwr.active ? '#b4f36d' : '#f77070', borderColor: '#222222' }"
					@click="vai(index + ((current_page - 1) * quantity))">
					<v-card-text class="flex column">
						<ul style="text-align: left; list-style: none; width: max-content;">
							<li>
								<span>
									<strong>ID:</strong> {{ fwr.id }}
								</span>
							</li>
							<li>
								<span>
									<strong>Reward ID:</strong> {{ fwr.reward_id }}
								</span>
							</li>
						</ul>
					</v-card-text>
					<v-card-text>
						<ul style="text-align: left; list-style: none;">
							<li>
								<span>
									<strong>Rarita:</strong> {{ rarities_name[fwr.rarity] }}
								</span>
							</li>
						</ul>
					</v-card-text>
				</v-card>
			</section>
			<section v-if="reward" id="update_fwr" class="fwr-column">
				<header>
					<h1>Modifica o elimina reward ruota fortuna</h1>
				</header>
				<v-card class="new-product-card"
					:style="{ backgroundColor: update_fwr.active ? '#b4f36d' : '#f77070', borderColor: '#222222' }">
					<v-card-text class="flex row">
						<div class="new-fwr-icon">
							<v-select v-model="update_chosen_rarity" solo :items="rarities_name">
							</v-select>
							<input type="checkbox" v-model="update_fwr.active" />
						</div>
						<div class="flex column">
							<ul class="event-list">
								<li>
									<div class="flex row">
										<strong>ID:</strong> <v-spacer></v-spacer> {{ update_fwr.id }}
									</div>
								</li>
								<li>
									<div class="flex row">
										<strong>Reward ID:</strong> <v-spacer></v-spacer> <input
											v-model="update_fwr.reward_id" />
									</div>
								</li>
							</ul>
						</div>
					</v-card-text>
				</v-card>
				<div class="row  v-margin10 space-around">
					<button class="new-event-btn" v-on:click="undo()">ANNULLA</button>
					<button class="new-event-btn" v-on:click="setFWvetrina()">CHECKPOINT</button>
				</div>
				<div class="row  v-margin10 space-around">
					<button class="new-event-btn" v-on:click="updateFW()">MODIFICA</button>
					<button class="new-event-btn" v-on:click="deleteFW()">ELIMINA</button>
				</div>
			</section>
		</div>
		<v-dialog v-model="shopView" persistent max-width="400">
			<v-card>
				<v-card-title class="text-h5">
					Ricompensa checkpoint
				</v-card-title>
				<v-card-text>A quale checkpoint vuoi assegnare questa reward di livello {{ rarities_name[update_fwr.rarity]
				}}?</v-card-text>
				<v-select v-model="chosen_checkpoint" solo :items="checkpoint">
				</v-select>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="green darken-1" text @click="shopView = false">
						Annulla
					</v-btn>
					<v-btn color="green darken-1" text @click="sendFWvetrina">
						Conferma
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>


<script>
import axios from 'axios';
import store from '@/store/index.js'


export default {
	name: 'FWRewardView',
	data: () => ({
		fwr: {
			"id": 0,
			"active": 0,
			"rarity": 0,
			"reward_id": 0,
		},
		shopView: false,
		chosen_checkpoint: "",
		chosen_rarity: "",
		update_chosen_rarity: "",
		update_fwr: {},
		search: false,
		snackbar: false,
		snackbar_text: '',
		quantita: [15, 30, 50, 100],
		quantity: 15,
		event_id_filter: '',
		range_filter: '',
		range_id_filter: '',
		category_filter: '',
		type_filter: '',
		page: 1,
		pages: 0,
		response: false,
		reward: false,
	}),
	computed: {
		token() {
			return store.getters.token;
		},
		FW() {
			return store.getters.FW;
		},
		checkpoint() {
			var ch = [];

			for (var i in store.getters.checkpoint) {
				ch.push(i);
			}
			return ch;
		},
		rarities_name() {
			var ch = [];
			for (var i in store.getters.rarities) {
				ch.push(store.getters.rarities[i]['name']);
			}
			return ch;
		},
		checkpoints() {
			var ch = [];

			for (var i in store.getters.checkpoint) {
				var tr = {};
				tr.name = i;
				tr.reward = store.getters.checkpoint[i];
				ch.push(tr);
			}
			return ch;
		},
		current_page() {
			return this.page;
		},
		limit() {
			return store.getters.checkpoint_limit;
		}
	},
	methods: {
		rarities_index(name) {
			for (var i in store.getters.rarities) {
				if (store.getters.rarities[i]['name'] === name)
					return i;
			}
			return null;
		},
		changePage(num) {
			this.page = num;
		},
		vai(index) {
			this.update_fwr = Object.assign({}, this.FW[index]);
			this.update_chosen_rarity = this.rarities_name[this.update_fwr.rarity];
			this.reward = true;
		},
		undo() {
			this.update_fwr = {};
			this.reward = false;
		},
		async setFWvetrina() {
			if (this.update_fwr.lenght !== 0)
				this.shopView = true;
		},
		async sendFWvetrina() {
			this.shopView = false;
			var body = {
				'reward_id': this.update_fwr.reward_id,
				'checkpoint': this.chosen_checkpoint
			};
			await axios
				.post('fortune/wheel/checkpoint/reward', body)
				.then((res) => {
					if (res != undefined && res.data.status) {
						store.dispatch("setCheckpoint", res.data.data);
						store.dispatch('setResponse', true);
						store.dispatch('setSnackbarText', "Aggiunto a vetrina ID: " + this.update_fwr.id);
						store.dispatch('setSnackbar', true);
					} else {
						store.dispatch('setResponse', false);
						store.dispatch('setSnackbarText', res.data.data);
						store.dispatch('setSnackbar', true);
					}
				}).catch((error) => {
					store.dispatch('setResponse', false);
					store.dispatch('setSnackbarText', error.response.data.data);
					store.dispatch('setSnackbar', true);
				});
		},
		async createFW() {
			this.fwr.rarity = this.rarities_index(this.chosen_rarity);
			await axios
				.post('fortune/wheel/reward/create/', this.fwr)
				.then((res) => {
					if (res != undefined && res.data.status) {
						store.dispatch("setFW", res.data.data);
						store.dispatch('setResponse', true);
						store.dispatch('setSnackbarText', "Aggiunto a vetrina ID: " + this.update_fwr.id);
						store.dispatch('setSnackbar', true);
					} else {
						store.dispatch('setResponse', false);
						store.dispatch('setSnackbarText', res.data.data);
						store.dispatch('setSnackbar', true);
					}
				}).catch((error) => {
					store.dispatch('setResponse', false);
					store.dispatch('setSnackbarText', error.response.data.data);
					store.dispatch('setSnackbar', true);
				});
		},
		async updateFW() {
			this.update_fwr.rarity = this.rarities_index(this.update_chosen_rarity);

			await axios
				.put('fortune/wheel/reward/update/' + this.update_fwr.id, this.update_fwr)
				.then((res) => {
					if (res != undefined && res.data.status) {
						store.dispatch("setFW", res.data.data);
						store.dispatch('setResponse', true);
						store.dispatch('setSnackbarText', "Aggiunto a vetrina ID: " + this.update_fwr.id);
						store.dispatch('setSnackbar', true);
					} else {
						store.dispatch('setResponse', false);
						store.dispatch('setSnackbarText', res.data.data);
						store.dispatch('setSnackbar', true);
					}
				}).catch((error) => {
					store.dispatch('setResponse', false);
					store.dispatch('setSnackbarText', error.response.data.data);
					store.dispatch('setSnackbar', true);
				});
		},
		async deleteFW() {
			await axios
				.delete('fortune/wheel/reward/delete/' + this.update_fwr.id)
				.then((res) => {
					if (res != undefined && res.data.status) {
						store.dispatch("setFW", res.data.data.rewards);
						store.dispatch("setCheckpoint", res.data.data.check);
						store.dispatch('setResponse', true);
						store.dispatch('setSnackbarText', "Aggiunto a vetrina ID: " + this.update_fwr.id);
						store.dispatch('setSnackbar', true);
					} else {
						store.dispatch('setResponse', false);
						store.dispatch('setSnackbarText', res.data.data);
						store.dispatch('setSnackbar', true);
					}
				}).catch((error) => {
					store.dispatch('setResponse', false);
					store.dispatch('setSnackbarText', error.response.data.data);
					store.dispatch('setSnackbar', true);
				});
		},
	},
}
</script>


<style>
.filter-bar {
	height: fit-content;
	padding: 10px;
	background-color: var(--secondary-color);
	border-radius: 15px;
	color: white;
}

.side-legend {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 100px;
	right: 0;
	width: 300px;
	border-radius: 17px;
	margin: 5px;
	padding: 10px;
	background-color: #b2ff9f;
}

.search-tools {
	position: absolute;
	display: flex;
	flex-direction: column;
	background-color: var(--secondary-color);
	margin: 20px;
	padding: 10px;
	border-radius: 20px;
	border: 2px solid black;
	right: 0;
}

.pages-index {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.pages-index span {
	font-family: 'Fredoka';
	font-weight: 600;
	color: #faebd7;
	cursor: pointer;
	min-width: 24px;
}


.search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.search-row .v-input {
	max-width: 90px;
}

.search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}

.cat-search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.cat-search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.cat-search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}


.fwr-body {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 110px;
	gap: 10px;
	overflow-x: scroll;
}

.fwr-column {
	border-radius: 20px;
	width: fit-content;
	height: fit-content;
	box-shadow: 0 5px 15px rgb(86, 193, 5);
	margin-top: 20px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 20px;
	padding: 10px;
}

.fwr-body h1 {
	font-family: 'Cairo';
	font-weight: 900;
	color: #ffe4c4;
	text-align: flex-start;
}

.fwr-icon {
	display: flex;
	flex-direction: column;
	margin: 10px;
	min-width: 60px;
}

.new-fwr-icon {
	display: flex;
	flex-direction: column;
	max-width: 250px;
}

.fwr-card {
	margin: 5px;
	border: solid 3px;
	align-items: center;
}

.fwr-card .row {
	margin: 10px;
}

.fwr-card strong {
	margin-right: 13px;
}

.fwr-card input {
	max-width: 65px;
	min-width: 50px;
}

.fwr-body input {
	min-height: 30px;
	margin: 0 auto;
}

.title-icon {
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: 1rem;
}

.title-icon h1 {
	width: fit-content;
}


.table-header {
	background-color: antiquewhite;
	box-shadow: 0 3px 5px black;
}

.table-header th {
	padding: 2px;
}

.header-row {
	display: flex;
	flex-direction: row;
}

.div-tile {
	display: flex;
	flex-direction: row;
	margin: 2px;
}

.table-tile {
	background-color: cadetblue;
}

.new-event-btn {
	width: 150px;
	height: 50px;
	background-color: antiquewhite;
	border-radius: 20px;
	border: 2px solid black;
}
</style>