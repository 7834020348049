<template>
	<div class="bird-body">
		<section class="bird-column">
			<header>
				<h1>Aggiungi uccello</h1>
			</header>
			<v-card class="new-product-card"
				:style="{ backgroundColor: bird.active ? '#b4f36d' : '#f77070', borderColor: '#222222' }">
				<v-card-text class="flex row">
					<div class="new-bird-icon">
						<div class="flex row">
							<strong>Nome:</strong> <v-spacer></v-spacer> <input v-model="bird.name" />
						</div>
						<div class="flex row">
							<strong>ID:</strong> <v-spacer></v-spacer> <input v-model="bird.bird_id" />
						</div>
						<v-select v-model="chosen_rarity" solo :items="rarity_name">
						</v-select>
						<input type="checkbox" v-model="bird.active" />
					</div>
					<div class="flex column">
						<ul class="event-list">
							<li>
								<div class="flex row">
									<strong>Punti minimi:</strong> <v-spacer></v-spacer> <input v-model="bird.min_points" />
								</div>
							</li>
							<li>
								<div class="flex row">
									<strong>Distanza:</strong> <v-spacer></v-spacer> <input v-model="bird.distance" />
								</div>
							</li>
							<li>
								<div class="flex row">
									<strong>Probabilità:</strong> <v-spacer></v-spacer> <input v-model="bird.probability" />
								</div>
							</li>
						</ul>
					</div>
					<div class="flex column">
						<ul class="event-list">
							<li>
								<div class="flex row">
									<strong>Punti massimi:</strong> <v-spacer></v-spacer> <input
										v-model="bird.max_points" />
								</div>
							</li>
							<li>
								<div class="flex row">
									<strong>ID camera:</strong> <v-spacer></v-spacer> <input v-model="bird.cam_id" />
								</div>
							</li>
							<li>
								<div class="flex row">
									<strong>ID scenario:</strong> <v-spacer></v-spacer> <input v-model="bird.scenery_id" />
								</div>
							</li>
						</ul>
					</div>
				</v-card-text>
			</v-card>
			<button class="new-birds-btn" v-on:click="createbirds">CONFERMA</button>
		</section>
		<section id="update_bird" class="bird-column">
			<header>
				<h1>Modifica o elimina uccello</h1>
			</header>
			<v-card class="new-product-card"
				:style="{ backgroundColor: update_bird.active ? '#b4f36d' : '#f77070', borderColor: '#222222' }">
				<v-card-text class="flex row">
					<div class="new-bird-icon">
						<div class="flex row">
							<strong>Nome:</strong> <v-spacer></v-spacer> <input v-model="update_bird.name" />
						</div>
						<div class="flex row">
							<strong>ID:</strong> <v-spacer></v-spacer> <input v-model="update_bird.bird_id" />
						</div>
						<v-select v-model="update_chosen_rarity" solo :items="rarity_name">
						</v-select>
						<input type="checkbox" v-model="update_bird.active" />
					</div>
					<div class="flex column">
						<ul class="event-list">
							<li>
								<div class="flex row">
									<strong>Punti minimi:</strong> <v-spacer></v-spacer> <input
										v-model="update_bird.min_points" />
								</div>
							</li>
							<li>
								<div class="flex row">
									<strong>Distanza:</strong> <v-spacer></v-spacer> <input
										v-model="update_bird.distance" />
								</div>
							</li>
							<li>
								<div class="flex row">
									<strong>Probabilità:</strong> <v-spacer></v-spacer> <input
										v-model="update_bird.probability" />
								</div>
							</li>
						</ul>
					</div>
					<div class="flex column">
						<ul class="event-list">
							<li>
								<div class="flex row">
									<strong>Punti massimi:</strong> <v-spacer></v-spacer> <input
										v-model="update_bird.max_points" />
								</div>
							</li>
							<li>
								<div class="flex row">
									<strong>ID camera:</strong> <v-spacer></v-spacer> <input v-model="update_bird.cam_id" />
								</div>
							</li>
							<li>
								<div class="flex row">
									<strong>ID scenario:</strong> <v-spacer></v-spacer> <input
										v-model="update_bird.scenery_id" />
								</div>
							</li>
						</ul>
					</div>
				</v-card-text>
			</v-card>
			<button class="new-birds-btn" v-on:click="undo()">ANNULLA</button>
			<button class="new-birds-btn" v-on:click="updatebirds()">MODIFICA</button>
			<button class="new-birds-btn" v-on:click="deletebirds()">ELIMINA</button>
		</section>
		<section class="bird-column">
			<header class="title-icon">
				<v-icon large >mdi-bird</v-icon>
				<h1>Uccelli</h1>
			</header>
			<div class="flex row">
				<div class="search-row">
					<p>
						<strong>Items:</strong>
					</p>
					<div style="width: 10px;"></div>
					<v-select v-model="quantity" solo :items="quantita" v-on:input="trier()" />
					<div style="width: 10px;"></div>
					<p>/ {{ filter_birds.length }}</p>
				</div>
				<v-spacer></v-spacer>
				<v-icon large  v-on:click="toggleSearch">
					mdi-search-web
				</v-icon>
				<div v-if="search" class="search-tools">
					<div class="search-row">
						<p>
							ID scenario:
						</p>
						<v-spacer></v-spacer>
						<input type="text" name="input-scenery-id" v-model="scenery_filter">
					</div>
					<div class="search-row">
						<p>
							ID camera:
						</p>
						<v-spacer></v-spacer>
						<input type="text" name="input-cam-id" v-model="cam_id_filter">
					</div>
					<div class="search-row">
						<p>
							ID:
						</p>
						<v-spacer></v-spacer>
						<input type="text" name="input-bird-id" v-model="bird_id_filter">
					</div>
				</div>
			</div>
			<div class="pages-index">
				<span v-for="num in pages" :key="num" v-on:click="changePage(num)"
					:style="{ color: page === num ? '#b4f36d' : '#faebd7' }">{{ num }}</span>
			</div>
			<v-card
				v-for="( bird, index ) in filter_birds.slice(((current_page - 1) * quantity), (current_page * quantity))"
				:key="index" class="flex bird-card"
				:style="{ backgroundColor: bird.active ? '#b4f36d' : '#f77070', borderColor: '#222222' }"
				@click="vai(index + ((current_page - 1) * quantity))">
				<v-card-text class="flex column">
					<ul style="text-align: left; list-style: none; width: max-content;">
						<li>
							<span>
								<strong>Nome:</strong> {{ bird.name }}
							</span>
						</li>
						<li>
							<span>
								<strong>ID:</strong> {{ bird.bird_id }}
							</span>
						</li>
						<li>
							<span>
								<strong>Rarità:</strong> {{ rarity_name[bird.rarity] }} {{ bird.rarity }}
							</span>
						</li>
					</ul>
				</v-card-text>
				<v-card-text class="flex column">
					<ul style="text-align: left; list-style: none; width: max-content;">
						<li>
							<span>
								<strong>Punti minimi:</strong> {{ bird.min_points ?? 0 }}
							</span>
						</li>
						<li>
							<span>
								<strong>Distanza:</strong> {{ bird.distance.toFixed(2) }}
							</span>
						</li>
						<li>
							<span>
								<strong>Probabilità:</strong>
							</span>
						</li>
					</ul>
				</v-card-text>
				<v-card-text>
					<ul style="text-align: left; list-style: none;">
						<li>
							<span>
								<strong>Punti massimi:</strong> {{ bird.max_points ?? 0 }}
							</span>
						</li>
						<li>
							<span>
								<strong>ID camera:</strong> {{ bird.cam_id }}
							</span>
						</li>
						<li>
							<span>
								<strong>ID scenario:</strong> {{ bird.scenery_id }}
							</span>
						</li>
					</ul>
				</v-card-text>
			</v-card>
		</section>
	</div>
</template>


<script>
import axios from 'axios';
import store from '@/store/index.js'
import { getBirds } from '../functions.js';


export default {
	name: 'BirdsView',
	data: () => ({
		bird: {
			"active": 0,
			"cam_id": 0,
			"bird_id": 0,
			"name": '',
			"min_points": 0,
			"max_points": 0,
			"distance": 0,
			"rarity": 0,
			"probability": 0,
			"scenery_id": 0,
		},
		update_bird: {},
		search: false,
		chosen_rarity: '',
		update_chosen_rarity: '',
		rarity_name: [
			"Comune",
			"Raro",
			"Epico",
			"Mitico",
			"Leggendario",
			"Speciale"
		],
		rarity_type: {
			"Comune": 0,
			"Raro": 1,
			"Epico": 2,
			"Mitico": 3,
			"Leggendario": 4,
			"Speciale": 5
		},
		quantita: [15, 30, 50, 100],
		quantity: 15,
		scenery_filter: '',
		cam_id_filter: '',
		bird_id_filter: '',
		page: 1,
		pages: 0,
	}),
	computed: {
		token() {
			return store.getters.token;
		},
		birds() {
			return store.getters.birds;
		},
		filter_birds() {
			return this.birds.filter(bird =>
			(
				bird.scenery_id.toString().includes(this.scenery_filter) &&
				bird.cam_id.toString().includes(this.cam_id_filter) &&
				bird.bird_id.toString().includes(this.bird_id_filter)
			)
			);
		},
		current_page() {
			return this.page;
		}
	},
	methods: {
		changePage(num) {
			this.page = num;
		},
		trier() {
			var main = Math.floor(this.filter_birds.length / this.quantity);
			var resto = this.filter_birds.length % this.quantity;
			this.pages = main + (resto > 0 ? 1 : 0);
			this.page = 1;
		},
		toggleSearch() {
			this.search = !this.search;
			if (!this.search)
				return;

			setTimeout(() => {
				document.getElementsByName("input-scenery-id")[0].addEventListener('input', this.filterList);
				document.getElementsByName("input-cam-id")[0].addEventListener('input', this.filterList);
				document.getElementsByName("input-bird-id")[0].addEventListener('input', this.filterList);
			}, 100);
		},
		vai(index) {
			this.update_bird = Object.assign({}, this.filter_birds[index]);
			this.update_chosen_rarity = this.rarity_name[this.update_bird.rarity];
			if (this.update_bird.min_points == null)
				this.update_bird.min_points = 0;
			if (this.update_bird.max_points == null)
				this.update_bird.max_points = 0;
			document.getElementById('update_bird').scrollIntoView();
		},
		undo() {
			this.update_bird = {};
		},
		async createbirds() {
			this.bird.rarity = this.rarity_type[this.chosen_rarity];
			await axios
				.post('birds/create/', this.bird)
				.then((res) => {
					if (res != undefined && res.data.status) {
						store.dispatch("setBirds", res.data.data);
						store.dispatch('setResponse', true);
						store.dispatch('setSnackbarText', "Aggiunto uccello");
						store.dispatch('setSnackbar', true);

					} else {
						store.dispatch('setResponse', false);
						store.dispatch('setSnackbarText', res.data.data);
						store.dispatch('setSnackbar', true);
					}
				}).catch((error) => {
					store.dispatch('setResponse', false);
					store.dispatch('setSnackbarText', error.response.data.data ?? error);
					store.dispatch('setSnackbar', true);
					setTimeout(() => {
						store.dispatch('setSnackbar', false);
					}, 2500);
				});
		},
		async updatebirds() {
			this.update_bird.rarity = this.rarity_type[this.update_chosen_rarity];
			await axios
				.put('birds/update/' + this.update_bird.id, this.update_bird)
				.then((res) => {
					if (res != undefined && res.data.status) {
						store.dispatch("setBirds", res.data.data);
						store.dispatch('setResponse', true);
						store.dispatch('setSnackbarText', "Modificato uccello ID: " + this.update_bird.id);
						store.dispatch('setSnackbar', true);

					} else {
						store.dispatch('setResponse', false);
						store.dispatch('setSnackbarText', res.data.data);
						store.dispatch('setSnackbar', true);
					}
				}).catch((error) => {
					store.dispatch('setResponse', false);
					store.dispatch('setSnackbarText', error.response.data.data ?? error);
					store.dispatch('setSnackbar', true);
					setTimeout(() => {
						store.dispatch('setSnackbar', false);
					}, 2500);
				});
		},
		async deletebirds() {
			await axios
				.delete('birds/delete/' + this.update_bird.id)
				.then((res) => {
					if (res != undefined && res.data.status) {
						store.dispatch("setBirds", res.data.data);
						store.dispatch('setResponse', true);
						store.dispatch('setSnackbarText', "Eliminato uccello ID: " + this.update_bird.bird_id);
						store.dispatch('setSnackbar', true);

					} else {
						store.dispatch('setResponse', false);
						store.dispatch('setSnackbarText', res.data.data);
						store.dispatch('setSnackbar', true);

					}
				}).catch((error) => {
					store.dispatch('setResponse', false);
						store.dispatch('setSnackbarText', error.response.data.data ?? error);
						store.dispatch('setSnackbar', true);

				});
		},
		filterList() {
			store.dispatch('setFilterBirds', this.birds.filter(bird =>
			(
				bird.scenery_id.toString().includes(this.scenery_filter) &&
				bird.cam_id.toString().includes(this.cam_id_filter) &&
				bird.bird_id.toString().includes(this.bird_id_filter)
			)
			));
			this.trier();
		},
	},
	async mounted() {
		await getBirds();
		setTimeout(() => {
			var main = Math.floor(this.filter_birds.length / this.quantity);
			var resto = this.filter_birds.length % this.quantity;
			this.pages = main + (resto > 0 ? 1 : 0);
		}, 1000);
	}
}
</script>


<style>
.search-tools {
	position: absolute;
	display: flex;
	flex-direction: column;
	background-color: var(--secondary-color);
	margin: 20px;
	padding: 10px;
	border-radius: 20px;
	border: 2px solid black;
	right: 0;
}

.pages-index {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.pages-index span {
	font-family: 'Fredoka';
	font-weight: 600;
	color: #faebd7;
	cursor: pointer;
	min-width: 24px;
}


.search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.search-row .v-input {
	max-width: 90px;
}

.search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}

.cat-search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.cat-search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.cat-search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}


.bird-body {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin: 20px auto;
	gap: 10px;
	overflow-x: scroll;
}

.bird-column {
	border-radius: 20px;
	box-shadow: 0 5px 15px rgb(86, 193, 5);
	margin-top: 20px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 20px;
	padding: 10px;
}

.bird-body h1 {
	font-family: 'Cairo';
	font-weight: 900;
	color: #ffe4c4;
	text-align: flex-start;
}

.bird-icon {
	display: flex;
	flex-direction: column;
	margin: 10px;
	min-width: 60px;
}

.new-bird-icon {
	display: flex;
	flex-direction: column;
	max-width: 250px;
}

.bird-card {
	margin: 5px;
	border: solid 3px;
	align-items: center;
}

.bird-card .row {
	margin: 10px;
}

.bird-card strong {
	margin-right: 13px;
}

.bird-card input {
	max-width: 65px;
	min-width: 50px;
}

.bird-body input {
	min-height: 30px;
	margin: 0 auto;
}

.title-icon {
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: 1rem;
}

.title-icon h1 {
	width: fit-content;
}


.table-header {
	background-color: antiquewhite;
	box-shadow: 0 3px 5px black;
}

.table-header th {
	padding: 2px;
}

.header-row {
	display: flex;
	flex-direction: row;
}

.div-tile {
	display: flex;
	flex-direction: row;
	margin: 2px;
}

.table-tile {
	background-color: cadetblue;
}

.new-birds-btn {
	width: 150px;
	height: 50px;
	background-color: antiquewhite;
	border-radius: 20px;
	border: 2px solid black;
}
</style>