<template>
  <div>

    <div :style="{ position: 'absolute', width: '100%', zIndex: sidebar ? 8 : 0, cursor: 'pointer' }">
      <v-app-bar style="position: relative;" elevation="2" color="var(--secondary-color)" v-on:click="toggleSideBar()">
        <h2 style="text-align: center; color: bisque;">
          {{ index < 17 ? birder_sidebar_items[index - 1].title : ara_sidebar_items[index - 17].title }} </h2>
            <v-spacer></v-spacer>
            <div class="nav-bar">
              <template v-for="item in index < 17 ? birder_sidebar_items : ara_sidebar_items">
                <v-icon v-if="item.role <= roleLevel" large :color="index == item.index ? 'green' : ''"
                  :key="item.title" v-on:click="pushPage(item.index)">
                  mdi-{{ item.icon }}
                </v-icon>
              </template>
              <v-icon large id="logout-button" v-on:click="pushPage(index < 17 ? 17 : 1)">
                {{ 'mdi-' + (index < 17 ? 'egg' : 'bird') }} </v-icon>
                  <v-icon large id="logout-button" v-on:click="logout($event)">mdi-exit-to-app</v-icon>
            </div>
      </v-app-bar>

      <v-navigation-drawer color="var(--secondary-color)" style="position:relative; height: 90vh; width: 300px;"
        v-model="sidebar" temporary>
        <v-img class="margin20" alt="The Birders" src="@/assets/images/logobar.webp"></v-img>
        <v-divider class="margin10"></v-divider>
        <v-list nav>
          <template v-for="item in index < 17 ? birder_sidebar_items : ara_sidebar_items">
            <v-list-item :key="item.title" v-on:click="pushPage(item.index)" v-if="item.role <= roleLevel">
              <v-list-item-icon>
                <v-icon large>mdi-{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title style="color: bisque;"><strong>{{ item.title }}</strong></v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item id="logout-button" v-on:click="pushPage(index < 17 ? 17 : 1)">
            <v-icon large>{{ 'mdi-' + (index < 17 ? 'egg' : 'bird') }}</v-icon>
                <v-list-item-title style="color: bisque;"><strong>{{ index < 17 ? 'Aragotchi' : 'The Birders' }}</strong></v-list-item-title>
          </v-list-item>
          <v-list-item id="logout-button" v-on:click="logout()">
            <v-icon large>mdi-exit-to-app</v-icon>
            <v-list-item-title style="color: bisque;"><strong>Log out</strong></v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <v-dialog persistent v-model="appDialog" transition="dialog-top-transition" max-width="600">
        <v-card>
          {{ contentAppDialog }}
          <v-card-actions class="justify-end">
            <button v-on:click="toggleDialog()">CHIUDI</button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
    <div v-on:click="toggleHelp()" class="help-icon">
      <v-icon>mdi-help</v-icon>
    </div>
    <DraggableItem id="help-tab">
      <SideLegend />
    </DraggableItem>
  </div>
</template>
    <script>
    import store from '@/store';
    import { logOut } from '../functions.js';
    import DraggableItem from './DraggableItem.vue';
    import SideLegend from './SideLegend.vue';
    export default {
      name: "AppBar",
      data: () => ({
        sidebar: false,
        help: false,
        positions: {
          clientX: 0,
          clientY: 0,
          movementX: 0,
          movementY: 0
        },
        birder_sidebar_items: [
          { title: "Prodotti", icon: 'store', index: 1, role: 2 },
          { title: "Pacchetti", icon: 'cards', index: 2, role: 2 },
          { title: "Premi", icon: 'gift', index: 3, role: 2 },
          { title: "Eventi", icon: 'calendar', index: 4, role: 2 },
          { title: "Categorie eventi", icon: 'notification-clear-all', index: 5, role: 2 },
          { title: "Missioni", icon: 'file-tree', index: 6, role: 2 },
          { title: "Codici", icon: 'barcode', index: 7, role: 2 },
          { title: "Domanda FAQ", icon: 'frequently-asked-questions', index: 8, role: 2 },
          { title: "Uccelli", icon: 'bird', index: 9, role: 2 },
          { title: "Ruota Fortuna", icon: 'ferris-wheel', index: 10, role: 2 },
          { title: "Daily Bird", icon: 'sun-clock', index: 11, role: 2 },
          { title: "Emails", icon: 'email-seal', index: 12, role: 2 },
          { title: "Pubblicita", icon: 'advertisements', index: 13, role: 2 },
          { title: "Statistiche", icon: 'file-account', index: 14, role: 2 },
          { title: "Oggetti in-game", icon: 'bitcoin', index: 15, role: 2 },
          { title: "Info", icon: 'information-outline', index: 16, role: 1 },
        ],
        ara_sidebar_items: [
          { title: "Impostazioni", icon: 'cog', index: 17, role: 2 },
          { title: "Malattie", icon: 'heart-pulse', index: 18, role: 2 },
          { title: "Azioni", icon: 'dumbbell', index: 19, role: 2 },
          { title: "Richieste giornaliere", icon: 'sun-clock', index: 20, role: 2 },
          { title: "Istruzioni", icon: 'notebook', index: 21, role: 2 },
          { title: "Forniture", icon: 'table-chair', index: 22, role: 2 },
          { title: "API", icon: 'text-box', index: 23, role: 2 },
        ],
      }),
      components: { DraggableItem, SideLegend },
      computed: {
        token() {
          return store.getters.token;
        },
        email() {
          var email = store.getters.email.indexOf("@");
          return store.getters.email.substring(0, email);
        },
        role() {
          return store.getters.role;
        },
        roleLevel() {
          switch (store.getters.role) {
            case 'root':
              return 4;
            case 'developer':
              return 3;
            case 'editor':
              return 2;
            case 'bot':
              return 1;
            default:
              return 0;
          }
        },
        appDialog() {
          return store.getters.appDialog;
        },
        contentAppDialog() {
          return store.getters.contentAppDialog;
        },
        logged() {
          return store.getters.logged;
        },
        index() {
          return store.getters.index;
        },
        baseUrl() {
          return process.env.VUE_APP_BASE_URL;
        }
      },
      methods: {
        toggleHelp() {
          this.help = !this.help;
          document.getElementById('help-tab').style.display = this.help ? 'block' : 'none';
        },
        scrollTop() {
          document.body.scrollTop = 0;
          document.documentElement.scrollTop = 0;
        },
        toggleDialog() {
          store.dispatch("toggleAppDialog");
        },
        toggleDeleteDialog() {
          store.dispatch("toggleDeleteAppDialog");
        },
        pushPage(str) {
          if (this.index !== str) {
            store.dispatch('setIndex', str);
          }
          this.toggleSideBar();
        },
        toggleSideBar() {
          this.sidebar = !this.sidebar;
        },
        logout(event) {
          event.stopPropagation();
          if (this.token !== null && this.token.length > 0) {
            if (logOut()) {
              this.sidebar = false;
              if (this.index !== 0)
                store.dispatch('setIndex', 0);
            }
          }
        },
      },
      mounted() {
        document.getElementById('help-tab').style.display = this.help ? 'block' : 'none';
      }
    }
</script>
    <style>
    .nav-bar {
      position: relative;
      display: flex;
      flex-direction: row;
      gap: 10px;
      justify-content: space-around;
    }

    .nav-bar .v-icon {
      transition: all 0.3s ease;
    }

    .nav-bar .v-icon:hover {
      color: var(--primary-color);
      transform: scale(1.2);
    }

    .help-icon {
      position: fixed;
      right: 10px;
      bottom: 10px;
      z-index: 100;
      background-color: #076d1f;
      border-radius: 30px;
      padding: 13px;
      margin: auto 7px;
    }

    #help-tab {
      position: fixed;
      right: 55px;
      bottom: 10px;
      z-index: 100;
      padding: 13px;
      margin: 20px;
      background-color: var(--secondary-color);
      border-radius: 20px;
      border: 2px solid black;
      text-align: left;
      font-style: italic;
    }

    #logout-button {
      background-color: #076d1f;
      border-radius: 20px 7px 20px 7px;
      display: flex;
      flex-direction: row;
      min-width: 60px;
      height: 50px;
      justify-content: center;
    }

    #logout-button * {
      margin: 0;
    }
  </style>