<template>
	<div class="event-body">
		<section style="position: relative; width: fit-content; margin: auto;" v-if="this.$store.getters.dev && !upd">
			<header style="color: white">
				<h3>Dev shortcuts</h3>
				<p>
					<strong>
						Attivazione/disattivazione degli eventi in blocco
					</strong>
				</p>
			</header>
			<div class="event-activation-container">
				<v-btn class="options-btn" v-on:click="activateEvents(false)">
					Disabilita
				</v-btn>
				<v-btn class="options-btn" v-on:click="activateEvents(true)">
					Attiva
				</v-btn>
			</div>
		</section>
		<div v-if="!upd" class="quest-body">
			<section class="event-column">
				<header class="title-icon">
					<v-icon large>mdi-calendar</v-icon>
					<h1>Eventi</h1>
				</header>
				<div class="flex row align-items gap20 v-margin10">
					<div class="search-row">
						<p>
							<strong>Items:</strong>
						</p>
						<div style="width: 10px;"></div>
						<v-select v-model="quantity" solo :items="quantita" v-on:input="trier()" />
						<div style="width: 10px;"></div>
						<p>/ {{ filter_events.length }}</p>
					</div>
					<div class="flex row align-items space-between filter-bar">
						<div class="flex fit-content">
							<span v-on:click="proa('created_at')">Creazione</span>
							<v-icon>{{ order_creat ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
						</div>
						<div class="flex fit-content">
							<span v-on:click="proa('updated_at')">Modifica</span>
							<v-icon>{{ order_update ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
						</div>
						<div class="flex fit-content">
							<span v-on:click="proa('id')">ID</span>
							<v-icon>{{ order_id ? 'mdi-menu-up' : 'mdi-menu-down' }}</v-icon>
						</div>
					</div>
					<v-icon large v-on:click="toggleSearch">
						mdi-search-web
					</v-icon>
				</div>
				<div class="pages-index">
					<span v-for="num in pages" :key="num" v-on:click="changePage(num)"
						:style="{ color: page === num ? '#b4f36d' : '#faebd7' }">{{ num }}</span>
				</div>
				<EventTile
					v-for="( event, index ) in filter_events.slice(((current_page - 1) * quantity), (current_page * quantity))"
					:key="index" :item="event" @event-clicked="selectEvent(index + ((current_page - 1) * quantity))">
				</EventTile>

			</section>
			<div id="sider">
				<section class="event-column">
					<header>
						<div class="add-event-bar">
							<h1>Aggiungi evento</h1>
							<v-icon size="47" class="l-margin20 r-margin10" color="green" v-on:click="add = !add">{{ add
								?
								'mdi-eye-off-outline' : 'mdi-eye-outline' }}</v-icon>
						</div>
					</header>
					<div v-if="add">
						<EventCard :new="true" />
						<button class="options-btn" v-on:click="askID">CONFERMA</button>
					</div>
				</section>
				<div v-if="search" class="search-tools">
					<div class="search-row">
						<p>
							ID evento:
						</p>
						<v-spacer></v-spacer>
						<input type="text" name="input-event-id" v-model="event_id_filter">
					</div>
					<div class="search-row">
						<p>
							Tipo:
						</p>
						<v-spacer></v-spacer>
						<input type="text" name="input-type" v-model="type_filter">
					</div>
				</div>
			</div>
		</div>
		<div v-else class="update-top-layout">
			<v-icon v-on:click="undo()" class="fit-content" large>mdi-arrow-left-bold</v-icon>
			<section class="event-column m-auto v-margin30">
				<header>
					<div class="add-event-bar">
						<h1>Modifica o elimina evento</h1>
					</div>
				</header>
				<div v-if="upd">
					<EventCard :new="false" />
					<div class="options-row">
						<button class="new-quests-btn" v-on:click="undo()">ANNULLA</button>
						<button class="options-btn" v-on:click="updateEvents()">MODIFICA</button>
						<button class="new-quests-btn" v-on:click="cloneDialog = !cloneDialog">CLONA</button>
						<button class="options-btn" v-on:click="sureDelete()">ELIMINA</button>
					</div>
				</div>
			</section>
			<section>
				<v-tabs v-model="tab" background-color="var(--secondary-color)" centered dark icons-and-text>

					<v-tab href="#tab-1">
						Categorie
						<v-icon>mdi-shape-plus</v-icon>
					</v-tab>

					<v-tab href="#tab-2">
						Missioni
						<v-icon>mdi-notebook</v-icon>
					</v-tab>

					<v-tab v-if="currentEvent.rewards" href="#tab-3">
						Ricompensa
						<v-icon>mdi-gift</v-icon>
					</v-tab>
				</v-tabs>

				<v-tabs-items background-color="trasparent" v-model="tab">
					<v-tab-item background-color="trasparent" v-for="i in 3" :key="i" :value="'tab-' + i">
						<EventQuestCategories v-if="i === 1"></EventQuestCategories>
						<EventQuests v-if="i === 2"></EventQuests>
						<EventRewards v-if="i === 3"></EventRewards>
					</v-tab-item>
				</v-tabs-items>
			</section>
		</div>
		<v-dialog transition="dialog-top-transition" max-width="600" v-model="care">
			<v-card>
				<v-toolbar color="primary" dark>Prestare attenzione</v-toolbar>
				<div class="text-h3 pa-12">CONFLITTO ID</div>
				<v-card-text>
					{{ dialogText }}
				</v-card-text>
				<v-card-actions class="justify-end">
					<v-btn text @click="createEvents">Conferma</v-btn>
					<v-btn text @click="care = !care">Chiudi</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog persistent v-model="cloneDialog" transition="dialog-top-transition" max-width="400">
			<v-card>
				<h1>
					Clona evento: {{ currentEvent.id }}.
				</h1>
				<p>
					<strong>
						Inserisci nuovo ID
					</strong>
				</p>
				<v-text-field style="max-width: 100px; margin: auto;" label="Nuovo ID" v-model="nuovoId"></v-text-field>
				<v-card-actions class="justify-end">
					<button style="margin: 5px;" v-on:click="cloneDialog = !cloneDialog">CHIUDI</button>
					<button style="margin: 5px;" v-on:click="cloneEvents">CONFERMA</button>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>


<script>
import store from '@/store/index.js'
import { changeEventsStatus } from '@/functions';
import EventCard from '@/views/Event/EventCard.vue';
import EventTile from '@/views/Event/EventTile.vue';
import EventQuestCategories from '@/views/Event/ManageEvent/EventQuestCategories.vue';
import EventQuests from '@/views/Event/ManageEvent/EventQuests.vue';
import EventRewards from '@/views/Event/ManageEvent/EventRewards.vue';
import axios from 'axios';

export default {
	name: 'EventsView',
	data: () => ({
		search: false,
		quantita: [15, 30, 50, 100],
		quantity: 15,
		event_id_filter: '',
		type_filter: '',
		cat_search: 9999,
		nuovoId: '',
		cloneDialog: false,
		page: 1,
		pages: 0,
		order_creat: false,
		order_update: false,
		order_id: false,
		add: false,
		upd: false,
		tab: null,
		text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
		care: false,
		dialogText: ''
	}),
	components: { EventCard, EventTile, EventQuestCategories, EventQuests, EventRewards },
	computed: {
		currentEvent() {
			return store.getters.updateEvent;
		},
		isUpdateSet() {
			return store.getters.updateEvent.id !== undefined;
		},
		token() {
			return store.getters.token;
		},
		events() {
			return store.getters.events;
		},
		filter_events() {
			return store.getters.events.filter(event =>
			(
				event.id.toString().includes(this.event_id_filter) &&
				event.type.toString().includes(this.type_filter)
			)
			);
		},
		snackbar() {
			return store.getters.snackbar;
		},
		snackbar_text() {
			return store.getters.snackbarText;
		},
		response() {
			return store.getters.response;
		},
		current_page() {
			return this.page;
		}
	},
	methods: {
		async askID() {
			if (store.getters.event.id == null || store.getters.event.id <= 0) {
				this.dialogText = "Stai creando un elemento senza ID. Il server la creera con un ID sequenziale che potrebbe portare a conflitti con la PROD. Procedi SOLO, se sai cosa stai facendo.";
				this.care = true;
				return;
			}
			axios
				//.get('https://2fgamestudio.com/taken/events?id=' + store.getters.event.id)
				.get('taken/events?id=' + store.getters.event.id)
				.then((res) => {
					if (res != undefined && res.data.status) {
						if (!res.data.data)
							this.createEvents();
						else {
							this.dialogText = "L'ID che stai inserendo esiste gia in PROD. Procedi SOLO, se sai cosa stai facendo.";
							this.care = true;
						}
					} else {
						this.care = true;
					}
				})
				.catch(() => {
					store.dispatch('setResponse', false);
					store.dispatch('setSnackbarText', "Errore");
					store.dispatch('setSnackbar', true);
				});
		},
		proa(column) {
			var flag = false;
			if (column === 'created_at') {
				this.order_creat = !this.order_creat
				flag = this.order_creat;
				this.filter_events.sort(function (a, b) {
					if (flag)
						return new Date(a[column]) > new Date(b[column]);
					else
						return new Date(a[column]) < new Date(b[column]);
				});
			}
			if (column === 'updated_at') {
				this.order_update = !this.order_update
				flag = this.order_update;
				this.filter_events.sort(function (a, b) {
					if (flag)
						return new Date(a[column]) > new Date(b[column]);
					else
						return new Date(a[column]) < new Date(b[column]);
				});
			}
			if (column === 'id') {
				this.order_id = !this.order_id
				flag = this.order_id;
				this.filter_events.sort(function (a, b) {
					if (flag)
						return a[column] > b[column];
					else
						return a[column] < b[column];
				});
			}
		},
		changePage(num) {
			this.page = num;
		},
		findCategory() {
			if (this.category_filter === 'Nessuna')
				this.cat_search = 9999;
			else
				this.cat_search = this.types_name.indexOf(this.category_filter);

			this.filterList();
		},
		trier() {
			var main = Math.floor(this.filter_events.length / this.quantity);
			var resto = this.filter_events.length % this.quantity;
			this.pages = main + (resto > 0 ? 1 : 0);
			this.page = 1;
		},
		toggleSearch() {
			this.search = !this.search;
			if (!this.search)
				return;

			setTimeout(() => {
				document.getElementsByName("input-event-id")[0].addEventListener('input', this.filterList);
				document.getElementsByName("input-type")[0].addEventListener('input', this.filterList);
			}, 100);
		},
		selectEvent(index) {
			store.dispatch('setUpdateEvent', this.filter_events[index]);
			if (!this.upd)
				this.upd = true;
		},
		undo() {
			this.resetUpdate();
			this.upd = false;
		},
		resetUpdate() {
			store.dispatch('setUpdateEvent', {});
			store.dispatch('setUpdateChosenEventType', '');
			this.upd = false;
		},
		resetClone() {
			store.dispatch('setUpdateEvent', {});
			store.dispatch('setUpdateChosenEventType', '');
			this.nuovoId = '';
			this.cloneDialog = false;
			this.upd = false;
		},
		resetNormal() {
			store.dispatch('setEvent', {
				active: false,
				rewards: '',
				prod_id: '',
				available_for: 0,
			});
			store.dispatch('setChosenEventType', '');
			this.add = false;
		},
		sureDelete() {
			let text = "Se confermi saranno permanentemente eliminate tutte le categorie e missioni legate a questo evento. Anche gli eventi in corso degli utenti andranno persi.";
			if (confirm(text))
				this.deleteEvents();
		},
		async createEvents() {
			this.care = false;
			store.dispatch('createEventRequest', this.resetNormal);
		},
		async updateEvents() {
			store.dispatch('updateEventRequest', this.resetUpdate);
		},
		async deleteEvents() {
			store.dispatch('deleteEventRequest', this.resetUpdate);
		},
		async cloneEvents() {
			if (this.nuovoId.length >= 1 && this.nuovoId > 0)
				store.dispatch('cloneEventRequest', {
					callback: this.resetClone,
					newId: this.nuovoId
				});
		},

		filterList() {
			store.dispatch('setFilterEvents', this.events.filter(event =>
			(
				event.id.toString().includes(this.event_id_filter) &&
				event.type.toString().includes(this.type_filter)
			)
			));
			this.trier();
		},
		activateEvents(status) {
			if (!store.getters.dev)
				return;
			changeEventsStatus(status);
		}
	},
	mounted() {
		var main = Math.floor(this.filter_events.length / this.quantity);
		var resto = this.filter_events.length % this.quantity;
		this.pages = main + (resto > 0 ? 1 : 0);
	}
}
</script>


<style>
v-text-field * {
	background-color: white;
	background: white;
}

.event-activation-container {
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	margin: 0 auto;
}

.add-event-bar {
	display: flex;
	flex-direction: row;
	margin: 10px auto;
	align-items: center;
	justify-content: space-between;
}

.filter-bar {
	height: fit-content;
	background-color: var(--secondary-color);
	border-radius: 15px;
	color: white;
}

.search-tools {
	display: flex;
	flex-direction: column;
	background-color: var(--secondary-color);
	margin: 20px;
	padding: 10px;
	border-radius: 20px;
	border: 2px solid black;
	right: 0;
}

.pages-index {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.pages-index span {
	font-family: 'Fredoka';
	font-weight: 600;
	color: #faebd7;
	cursor: pointer;
	min-width: 24px;
}


.search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.search-row .v-input {
	max-width: 90px;
}

.search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}

.cat-search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.cat-search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.cat-search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}


.event-body {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 110px;
	gap: 10px;
	height: max-content;
	padding: 20px auto;
}

.event-column {
	position: relative;
	width: fit-content;
	border-radius: 20px;
	box-shadow: 0 5px 15px rgb(86, 193, 5);
	margin: 20px 0;
	padding: 10px;
}

.event-body h1 {
	font-family: 'Cairo';
	font-weight: 900;
	color: #ffe4c4;
	text-align: flex-start;
	height: fit-content;
}

.event-icon {
	display: flex;
	flex-direction: column;
	margin: 10px;
	min-width: 60px;
}

.new-event-icon {
	display: flex;
	flex-direction: column;
	max-width: 250px;
}



.title-icon {
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: 1rem;
}

.title-icon h1 {
	width: fit-content;
}


.table-header {
	background-color: antiquewhite;
	box-shadow: 0 3px 5px black;
}

.table-header th {
	padding: 2px;
}

.header-row {
	display: flex;
	flex-direction: row;
}

.div-tile {
	display: flex;
	flex-direction: row;
	margin: 2px;
}

.table-tile {
	background-color: cadetblue;
}
</style>