<template>
    <div class="v-margin20 h-margin-auto">
        <label>
            Reward dedicate al daily bird
        </label>

        <div class="flex row">
            <div class="form-reward-container">
                <div class="flex column">

                    <label class="fit-content posix-label">Primo premio</label>
                    <div class="flex row">
                        <input class="position-input" id="posix1" v-model="rewards['0']">
                        <v-icon large class="btn-in-row" v-on:click="setReward(0)">mdi-plus</v-icon>
                        <v-icon large class="btn-in-row" v-on:click="delReward(0)">mdi-delete</v-icon>
                        <v-icon large class="btn-in-row" v-on:click="showReward(0)">mdi-eye-outline</v-icon>
                    </div>
                </div>
                <div class="flex column">

                    <label class="fit-content posix-label">Secondo premio</label>
                    <div class="flex row">
                        <input class="position-input" id="posix2" v-model="rewards['1']">
                        <v-icon large class="btn-in-row" v-on:click="setReward(1)">mdi-plus</v-icon>
                        <v-icon large class="btn-in-row" v-on:click="delReward(1)">mdi-delete</v-icon>
                        <v-icon large class="btn-in-row" v-on:click="showReward(1)">mdi-eye-outline</v-icon>
                    </div>
                </div>
                <div class="flex column">
                    <label class="fit-content posix-label">Terzo premio</label>
                    <div class="flex row">
                        <input class="position-input" id="posix3" v-model="rewards['2']">
                        <v-icon large class="btn-in-row" v-on:click="setReward(2)">mdi-plus</v-icon>
                        <v-icon large class="btn-in-row" v-on:click="delReward(2)">mdi-delete</v-icon>
                        <v-icon large class="btn-in-row" v-on:click="showReward(2)">mdi-eye-outline</v-icon>
                    </div>
                </div>
            </div>

            <div class="show-sample-reward">
                <RewardTile v-if="getShow" :item="reward"></RewardTile>
            </div>
        </div>

        <RewardsView></RewardsView>
    </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import RewardsView from "./Reward/RewardsView.vue";
import RewardTile from "./Reward/RewardTile.vue";

export default {
    name: "DailyBird",
    data() {
        return {
            rewards: [],
            show: false,
            index: 99,
            reward: {},
        };
    },
    methods: {
        async getReward() {
            await axios
                .get('dailybirds/rewards/get')
                .then((res) => {
                    if (res != undefined && res.data.status) {
                        this.rewards = res.data.data;
                    }
                    else {
                        store.dispatch('setResponse', false);
                        store.dispatch('setSnackbarText', res.data.data ?? "Errore");
                        store.dispatch('setSnackbar', true);
                    }
                })
                .catch((error) => {
                    store.dispatch('setResponse', false);
                    store.dispatch('setSnackbarText', error.response.data.data ?? "Errore: " + error);
                    store.dispatch('setSnackbar', true);
                });
        },
        async setReward(index) {
            await axios
                .get('dailybirds/rewards/set/' + index + '/' + this.rewards[index])
                .then((res) => {
                    if (res != undefined && res.data.status) {
                        this.rewards = res.data.data;
                        store.dispatch('setResponse', true);
                        store.dispatch('setSnackbarText', "Aggiunta ricompensa a daily bird");
                        store.dispatch('setSnackbar', true);
                    }
                    else {
                        store.dispatch('setResponse', false);
                        store.dispatch('setSnackbarText', res.data.data ?? "Errore");
                        store.dispatch('setSnackbar', true);
                    }
                })
                .catch((error) => {
                    store.dispatch('setResponse', false);
                    store.dispatch('setSnackbarText', error.response.data.data ?? "Errore: " + error);
                    store.dispatch('setSnackbar', true);
                });
        },
        async delReward(index) {
            await axios
                .get('dailybirds/rewards/del/' + index)
                .then((res) => {
                    if (res != undefined && res.data.status) {
                        this.rewards = res.data.data;
                        store.dispatch('setResponse', true);
                        store.dispatch('setSnackbarText', "Eliminata ricompensa da daily bird");
                        store.dispatch('setSnackbar', true);
                    }
                    else {
                        store.dispatch('setResponse', false);
                        store.dispatch('setSnackbarText', res.data.data ?? "Errore");
                        store.dispatch('setSnackbar', true);

                    }
                })
                .catch((error) => {
                    store.dispatch('setResponse', false);
                    store.dispatch('setSnackbarText', error.response.data.data ?? "Errore: " + error);
                    store.dispatch('setSnackbar', true);
                });
        },
        showReward(index) {
            if (index === this.index) {
                this.show = false;
                this.index = 99;
                this.reward = {};
                return;
            }

            this.index = index;
            store.getters.rewards.forEach(element => {
                if (element.id.toString() === this.rewards[this.index]) {
                    this.reward = element;
                }
            });
            this.show = true;
        },
    },
    computed: {
        getIndex() {
            return this.index;
        },
        getShow() {
            return this.show;
        }
    },
    mounted() {
        this.getReward();
    },
    components: { RewardsView, RewardTile }
}
</script>
<style>
.position-input {
    width: fit-content;
    margin: 5px;
}

.posix-label {
    color: bisque;
    font-size: 20px;
    margin-bottom: 10px;
    margin-top: 15px;
}

.btn-in-row {
    margin: 5px;
}
.form-reward-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 40%;
    height: fit-content;
    margin-left: 30px;
    gap: 20px;

}

.show-sample-reward {
    width: fit-content;
    margin: 30px auto;
}
</style>