import axios from 'axios';

const packModule = {
  state: {
    packs: [],
    filter_packs: [],
    pack: {
      "id": null,
      "active": true,
      "total_elements": 0,
      "min_coins": 0,
      "max_coins": 0,
      "min_feathers": 0,
      "max_feathers": 0,
      "commons": 0,
      "rares": 0,
      "epics": 0,
      "mythics": 0,
      "legends": 0,
      "specials": 0,
      "is_free": 0,
      "seconds_to_open": 0,
      "rank_id": 0,
      "pack_id": "",
      "scenery_id": 0,
    },
    updatePack: {},
    chosenPackCurrency: '',
    updateChosenPackCurrency: '',
    filter: {
      id_filter: '',
      nome_filter: '',
      scenery_id_filter: '',
      active_filter: false,
    },
    packCurrencies: [
      {
        name: "Gratis",
        icon: "mdi-cash-off",
      },
      {
        name: "Monete",
        icon: "mdi-hand-coin",
      },
      {
        name: "Piume",
        icon: "mdi-feather",
      },
      {
        name: "Moneta speciale",
        icon: "mdi-star",
      },
      {
        name: "Euro",
        icon: "mdi-currency-eur"
      }
    ],
    packCurrencyIndex: {
      "Gratis": 0,
      "Monete": 1,
      "Piume": 2,
      "Moneta speciale": 3,
      "Euro": 4
    },
  },
  getters: {
    packs(state) {
      return state.packs;
    },
    filter_packs(state) {
      return state.filter_packs;
    },
    pack(state) {
      return state.pack;
    },
    updatePack(state) {
      return state.updatePack;
    },
    chosenPackCurrency(state) {
      return state.chosenPackCurrency;
    },
    updateChosenPackCurrency(state) {
      return state.updateChosenPackCurrency;
    },
    packCurrency(state) {
      return state.packCurrencies;
    },
    packCurrencyIndex(state) {
      return state.packCurrencyIndex;
    },
    getPackIDfilter(state) {
      return state.filter.id_filter;
    },
    getPackNomefilter(state) {
      return state.filter.nome_filter;
    },
    getPackSceneryfilter(state) {
      return state.filter.scenery_id_filter;
    },
    getPackActivefilter(state) {
      return state.filter.active_filter;
    }
  },
  mutations: {
    setPacks(state, payload) {
      state.packs = payload;
      state.filter_packs = state.packs.filter(pack => {

        const idMatch = pack.id.toString().includes(state.filter.id_filter);
        const packIdMatch = (pack.pack_id.toLowerCase() ?? '').includes(state.filter.nome_filter.toLowerCase());
        const sceneryId = pack.scenery_id.toString().includes(state.filter.scenery_id_filter);

        let activeMatch;
        if (state.filter.active_filter) {
          activeMatch = pack.active === 1;
        } else {
          activeMatch = true;
        }

        return idMatch && packIdMatch && sceneryId && activeMatch;
      });
    },
    setFilterPacks(state, payload) {
      state.filter_packs = payload;
    },
    setPack(state, payload) {
      state.pack = payload;
    },
    setPackFilter(state, { filterName, value }) {
      switch (filterName) {
        case 0:
          state.filter.id_filter = value;
          break;
        case 1:
          state.filter.nome_filter = value;
          break;
        case 2:
          state.filter.scenery_id_filter = value;
          break;
        case 3:
          state.filter.active_filter = value;
          break;
        default:
          break;
      }
    },
    setUpdatePack(state, payload) {
      state.updatePack = payload;
      var isNull = false;
      if (payload.is_free == undefined)
        isNull = true;
      state.updateChosenPackCurrency = isNull ? '' : state.packCurrencies[payload.is_free].name;
    },
    setChosenPackCurrency(state, payload) {
      state.chosenPackCurrency = payload;
    },
    setUpdateChosenPackCurrency(state, payload) {
      state.updateChosenPackCurrency = payload;
    },
    filterPackList(state) {
      state.filter_packs = state.packs.filter(pack => {

        const idMatch = pack.id.toString().includes(state.filter.id_filter);
        const packIdMatch = (pack.pack_id.toLowerCase() ?? '').includes(state.filter.nome_filter.toLowerCase());
        const sceneryId = pack.scenery_id.toString().includes(state.filter.scenery_id_filter);

        let activeMatch;
        if (state.filter.active_filter) {
          activeMatch = pack.active === 1;
        } else {
          activeMatch = true;
        }

        return idMatch && packIdMatch && sceneryId && activeMatch;
      });
    }
  },
  actions: {
    setPacks({ commit }, payload) {
      commit('setPacks', payload);
      commit('filterPackList');
    },
    setFilterPacks({ commit }, payload) {
      commit('setFilterPacks', payload);
    },
    setPack({ commit }, payload) {
      commit('setPack', payload);
    },
    setUpdatePack({ commit }, payload) {
      commit('setUpdatePack', payload);
    },
    setChosenPackCurrency({ commit }, payload) {
      commit('setChosenPackCurrency', payload);
    },
    filterPackList({ commit }) {
      commit('filterPackList');
    },
    setUpdateChosenPackCurrency({ commit }, payload) {
      commit('setUpdateChosenPackCurrency', payload);
    },
    setPackIdfilter({ commit }, payload) {
      commit('setPackFilter', { filterName: 0, value: payload });
    },
    setPackNomefilter({ commit }, payload) {
      commit('setPackFilter', { filterName: 1, value: payload });
    },
    setPackSceneryIdfilter({ commit }, payload) {
      commit('setPackFilter', { filterName: 2, value: payload });
    },
    setPackActivefilter({ commit }, payload) {
      commit('setPackFilter', { filterName: 3, value: payload });
    },
    async getPackRequest({ commit }) {
      axios
        .get('packs/get')
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setPacks", res.data.data);
          } else {
            commit("logOut");
            commit('setLogged', false);
          }
        })
        .catch(() => {
          commit("logOut");
          commit('setLogged', false);
        });
    },
    async updatePackRequest({ commit, state }, callback) {
      const q = state.updatePack;
      q.is_free = state.packCurrencyIndex[state.updateChosenPackCurrency];
      await axios
        .put('packs/update/' + q.id, q)
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setPacks", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Modificato pacchetto ID: " + q.id);
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
        });
    },
    async duplicatePackRequest({ commit, state }, callback) {
      const q = state.updatePack;
      q.is_free = state.packCurrencyIndex[state.updateChosenPackCurrency];
      await axios
        .post('packs/create/', q)
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setPacks", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Creato nuovo pacchetto");
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
        });
    },
    async createPackRequest({ commit, state }, callback) {
      const q = state.pack;
      q.is_free = state.packCurrencyIndex[state.chosenPackCurrency];
      await axios
        .post('packs/create/', q)
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setPacks", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Creato nuovo pacchetto");
            commit('setSnackbar', true);
            commit('setPack', {
              "id": null,
              "active": true,
              "total_elements": 0,
              "min_coins": 0,
              "max_coins": 0,
              "min_feathers": 0,
              "max_feathers": 0,
              "commons": 0,
              "rares": 0,
              "epics": 0,
              "mythics": 0,
              "legends": 0,
              "specials": 0,
              "is_free": 0,
              "seconds_to_open": 0,
              "rank_id": 0,
              "pack_id": "",
              "scenery_id": 0,
            });
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
        });
    },
    async deletePackRequest({ commit, state }, callback) {
      await axios
        .delete('packs/delete/' + state.updatePack.id)
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setPacks", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Eliminato pacchetto ID: " + state.updatePack.id);
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
        });
    }
  },
};

export default packModule;
