<template>
    <v-card class="event-card" :style="{ backgroundColor: currentEvent.active ? '#b4f36d' : '#f77070' }">
        <v-card-text class="flex row align-items">
            <div class="flex column h-margin10 gap30">
                <div class="contain-select">
                    <v-select :value="chosenEventType" solo :items="typesName" @change="setType"></v-select>
                </div>
                <v-icon class="special-icon" large>
                    {{ typesIcons[chosenEventType] }}
                </v-icon>
                <div v-if="chosenEventType === 'A livello' || chosenEventType === 'Giornaliero'" class="event-row">
                    <strong>Livello:</strong>
                    <input v-model="currentEvent.unlock_level" />
                </div>
                <div class="event-row">
                    <strong>ID evento:</strong>
                    <input v-model="currentEvent.id" />
                </div>
                <div class="flex column">
                    <input type="checkbox" class="checkbox" v-model="currentEvent.active" />
                    <strong style="margin: auto;">{{ currentEvent.active ? 'Attivo' : 'Disattivo' }}</strong>
                </div>
            </div>
            <div class="flex column h-margin10 space-around">
                <div v-if="editRewards.length > 0" class="event-row v-margin10">
                    <strong>Rewards:</strong>
                    <EventRewardsList v-if="currentEvent.rewards" :new="isNew"
                        :list="currentEvent.rewards.length > 1 ? currentEvent.rewards : ''" :edit="true"
                        @reward-clicked="deleteReward" />
                </div>
                <div class="event-row">
                    <strong>Aggiungi Reward:</strong>
                    <CreateEventRewardsList @add-reward-clicked="addReward"></CreateEventRewardsList>
                </div>
                <div class="event-row">
                    <strong>Offerte:</strong>
                    <input v-model="currentEvent.prod_id" />
                </div>
            </div>
            <div class="flex column h-margin10 space-around">
                <div class="event-row">
                    <strong>ID row:</strong>
                    <input v-model="currentEvent.row_id" />
                </div>
                <div class="event-row">
                    <strong>Inizio:</strong>
                    <v-dialog v-model="initDialog" max-width="700">
                        <template v-slot:activator="{ props: activatorProps }">
                            <v-btn v-bind="activatorProps" v-on:click="initDialog = !initDialog">
                                {{ currentEvent.start_at }}
                            </v-btn>
                        </template>
                        <DateTimePicker @confirmed-date="confirmDate" @cancel-date="cancelDate" tag="init"
                            :initial-date="currentEvent.start_at" />
                    </v-dialog>
                </div>
                <div class="event-row">
                    <strong>Fine:</strong>
                    <v-dialog v-model="endDialog" max-width="700">
                        <template v-slot:activator="{ props: activatorProps }">
                            <v-btn v-bind="activatorProps" v-on:click="endDialog = !endDialog">
                                {{ currentEvent.expire_at }}
                            </v-btn>
                        </template>
                        <DateTimePicker @confirmed-date="confirmDate" @cancel-date="cancelDate" tag="end"
                            :initial-date="currentEvent.expire_at" />
                    </v-dialog>
                </div>
                <div class="event-row">
                    <strong>Disponibilità in:</strong>
                    <v-select class="dimensiona-select" :value="selectedUnitTime" solo :items="unitTimes"
                        @change="setTimeChange"></v-select>
                    <input v-model="localTime" @input="availableSeconds" />
                </div>
                <div class="event-row">
                    <strong>Difficolta:</strong>
                    <input v-model="currentEvent.difficulty" />
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>

import store from '@/store/index.js'
import CreateEventRewardsList from './CreateEventRewardsList.vue';
import EventRewardsList from './EventRewardsList.vue';
import DateTimePicker from '@/components/DateTimePicker.vue';


export default {
    name: "EventCard",
    data() {
        return {
            localTime: 0,
            initDialog: false,
            endDialog: false
        };
    },
    watch: {
        currentEvent: {
            handler() {
                if (this.new) {
                    store.dispatch('setChosenEventType', this.typesName[this.currentEvent.type]);
                }
                else {
                    store.dispatch('setUpdateChosenEventType', this.typesName[this.currentEvent.type]);
                }
            }
        }
    },
    props: {
        new: Boolean
    },
    methods: {
        cancelDate() {
            this.initDialog = false;
            this.endDialog = false;
        },
        confirmDate(data) {
            const { tag, date } = data;
            switch (tag) {
                case 'init':
                    this.currentEvent.start_at = date;
                    break;
                case 'end':
                    this.currentEvent.expire_at = date;
                    break;
                default:
                    break;
            }
            this.initDialog = false;
            this.endDialog = false;
        },
        setType(value) {
            if (this.new)
                store.dispatch('setChosenEventType', value);
            else
                store.dispatch('setUpdateChosenEventType', value);
        },
        availableSeconds() {
            var result = this.localTime;

            switch (this.unitTimesIndex[this.selectedUnitTime]) {
                case 1: // "Minuti"
                    result *= 60;
                    break;
                case 2: // "Ore"
                    result *= 3600;
                    break;
                case 3: // "Giorni"
                    result *= 86400;
                    break;
                case 4: // "Settimane"
                    result *= 604800;
                    break;
                default:
                    break;
            }

            this.currentEvent.available_for = result;
        },
        setTimeChange(value) {
            store.dispatch('setSelectedUnitTime', value);
            var index = this.unitTimesIndex[this.selectedUnitTime];

            var result = this.currentEvent.available_for;

            switch (index) {
                case 1: // "Minuti"
                    result /= 60;
                    break;
                case 2: // "Ore"
                    result /= 3600;
                    break;
                case 3: // "Giorni"
                    result /= 86400;
                    break;
                case 4: // "Settimane"
                    result /= 604800;
                    break;
                default:
                    break;
            }

            this.localTime = result;
        },
        formatType(value) {
            if (this.currentEvent.number_to_get !== null)
                return value.replace('X', this.currentEvent.number_to_get);
            else
                return value;
        },
        deleteReward(payload) {
            if (this.new)
                store.dispatch('removeEventRewards', payload);
            else
                store.dispatch('removeUpdateEventRewards', payload);
        },
        addReward(id, points) {
            if (id > 0 && points > 0) {
                if (this.new)
                    store.dispatch('addEventRewards', { 'id': id, 'points': points });
                else
                    store.dispatch('addUpdateEventRewards', { 'id': id, 'points': points });
            }
        }
    },
    computed: {
        currentEvent() {
            return this.new ? store.getters.event : store.getters.updateEvent;
        },
        chosenEventType() {
            return this.new ? store.getters.chosenEventType : store.getters.updateChosenEventType;
        },
        typesName() {
            return store.getters.eventTypesName;
        },
        unitTimes() {
            return store.getters.unitTimes;
        },
        unitTimesIndex() {
            return store.getters.unitTimesIndex;
        },
        selectedUnitTime() {
            return store.getters.selectedUnitTime;
        },
        typesIcons() {
            return store.getters.eventTypesIcons;
        },
        isNew() {
            return this.new;
        },
        editRewards() {
            if (this.new)
                return store.getters.eventRewards;
            else
                return store.getters.updateEventRewards;
        }
    },
    components: { CreateEventRewardsList, EventRewardsList, DateTimePicker },
    mounted() {
        store.dispatch('setSelectedUnitTime', "Secondi");
        this.localTime = this.currentEvent.available_for;
    },
}
</script>
<style>
.dimensiona-select {
    max-width: 150px;
}

.event-card {
    margin: 5px;
    border: 3px solid rgb(255, 255, 255);
    align-items: center;
    justify-content: space-around;
    border-radius: 10px;
    color: rgb(58, 58, 58);
}

.v-text-field__details {
    display: none;
}

.contain-select {
    max-height: 50px;
    width: fit-content;
    margin: 0 auto;
}

.event-row {
    display: flex;
    flex-direction: column;
    text-align: flex-start;
}

.event-row input {
    width: 100%;
    max-height: 50px;
}
</style>