<template>
    <div class="v-margin20 h-margin-auto">
        <label>
            Statistiche
        </label>
        <div class="stat-row">
            <SceneryBox v-for="(item, index) in firstGroup" :key="index" @onClick="cliccato" :valore="item.count"
                :index="index" :nome="item.nome" :media="item.lvl_avarage" :minimo="item.min_lvl"
                :massimo="item.max_lvl"></SceneryBox>
        </div>
        <div class="stat-row">
            <SceneryBox v-for="(item, index) in secondGroup" :key="index" @onClick="cliccato" :valore="item.count"
                :index="index + 3" :nome="item.nome" :media="item.lvl_avarage" :minimo="item.min_lvl"
                :massimo="item.max_lvl"></SceneryBox>
        </div>

        <SceneryDetails v-if="current !== -1" :scenery="current"></SceneryDetails>

    </div>
</template>
<script>
import axios from "axios";
import store from "@/store";
import SceneryBox from '@/views/Statistiche/SceneryBox.vue';
import SceneryDetails from '@/views/Statistiche/SceneryDetails.vue';
export default {
    name: "StatsPage",
    data() {
        return {
            users: [],
            delta: 0,
            minsme: 0,
            ever: 0,
            kruger: 0,
            hawaii: 0,
            manu: 0,
            current: -1,
            lista: {
                'delta': {
                    nome: 'Delta del Po',
                    count: 0,
                    lvl_avarage: 0,
                    min_lvl: 0,
                    max_lvl: 0
                },
                'minsme': {
                    nome: 'Minsmere',
                    count: 0,
                    lvl_avarage: 0,
                    min_lvl: 0,
                    max_lvl: 0
                },
                'ever': {
                    nome: 'Everglades',
                    count: 0,
                    lvl_avarage: 0,
                    min_lvl: 0,
                    max_lvl: 0
                },
                'kruger': {
                    nome: 'Kruger',
                    count: 0,
                    lvl_avarage: 0,
                    min_lvl: 0,
                    max_lvl: 0
                },
                'hawaii': {
                    nome: 'Hawaii',
                    count: 0,
                    lvl_avarage: 0,
                    min_lvl: 0,
                    max_lvl: 0
                },
                'manu': {
                    nome: 'Manu',
                    count: 0,
                    lvl_avarage: 0,
                    min_lvl: 0,
                    max_lvl: 0
                },
            },
        }
    },
    computed: {
        firstGroup() {
            // Slice the first 3 items (0 to 2) from lista object and return as an array
            return Object.values(this.lista).slice(0, 3);
        },
        secondGroup() {
            // Slice items 3 to 5 from lista object and return as an array
            return Object.values(this.lista).slice(3, 6);
        },
    },
    components: { SceneryBox, SceneryDetails },
    methods: {
        async getData() {
            await axios
                .get('editor/stats/get')
                .then((res) => {
                    if (res != undefined && res.data.status) {
                        this.users = res.data.data;
                        this.setData();
                    }
                    else {
                        store.dispatch('setResponse', false);
                        store.dispatch('setSnackbarText', res.data.data ?? "Errore");
                        store.dispatch('setSnackbar', true);
                    }
                })
                .catch((error) => {
                    store.dispatch('setResponse', false);
                    store.dispatch('setSnackbarText', error.response.data.data ?? "Errore: " + error);
                    store.dispatch('setSnackbar', true);
                });
        },
        setData() {
            this.users.forEach(element => {
                if (element.level < 15) {
                    this.elaboraTipo('delta', element.level)
                    return
                }
                if (element.level >= 15 && element.level < 35) {
                    this.elaboraTipo('minsme', element.level)
                    return
                }
                if (element.level >= 35 && element.level < 45) {
                    this.elaboraTipo('ever', element.level)
                    return
                }
                if (element.level >= 45 && element.level < 60) {
                    this.elaboraTipo('kruger', element.level)
                    return
                }
                if (element.level >= 60 && element.level < 70) {
                    this.elaboraTipo('hawaii', element.level)
                    return
                }
                if (element.level >= 70) {
                    this.elaboraTipo('manu', element.level)
                }
            });
            for (let key in this.lista) {
                let element = this.lista[key];
                if (element.count > 1)
                    this.lista[key].lvl_avarage = (element.lvl_avarage / element.count).toFixed(1);
            }
        },
        elaboraTipo(tag, level) {
            this.lista[tag].count += 1;
            this.lista[tag].lvl_avarage += level;
            if (this.lista[tag].min_lvl == 0) {
                this.lista[tag].min_lvl = level;
            }
            if (this.lista[tag].min_lvl > level) {
                this.lista[tag].min_lvl = level;
            }
            if (this.lista[tag].max_lvl == 0) {
                this.lista[tag].max_lvl = level;
            }
            if (this.lista[tag].max_lvl < level) {
                this.lista[tag].max_lvl = level;
            }
            return
        },
        cliccato(index) {
            if (this.current !== index)
            {
                this.current = -1;
                setTimeout(() => {
                    this.current = index; 
                }, 150);
            } else this.current = -1;
        }
},
mounted() {
    this.getData();
},
}
</script>
<style>
.stat-row {
    position: relative;
    max-width: 1100px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
}
</style>