import axios from 'axios';

const eventModule = {
  state: {
    events: [],
    filterEvents: [],
    eventCategories: [],
    event: {
      active: false,
      rewards: '',
      prod_id: '',
      available_for: 0,
    },
    chosenListCategory: null,
    eventRewards: [],
    updateEventRewards: [],
    updateEvent: {},
    chosenEventType: '',
    updateChosenEventType: '',
    eventTypesIcons: {
      "Tempo limitato": "mdi-clock-time-five-outline",
      "A livello": "mdi-account-arrow-up",
      "Giornaliero": "mdi-sun-clock",
    },
    eventTypesIndex: {
      "Tempo limitato": 0,
      "A livello": 1,
      "Giornaliero": 5
    },
    eventTypesName: [
      "Tempo limitato",
      "A livello",
      "Giornaliero",
    ],
  },
  getters: {
    events(state) {
      return state.events;
    },
    filterEvents(state) {
      return state.filterEvents;
    },
    eventCategories(state) {
      return state.eventCategories;
    },
    event(state) {
      return state.event;
    },
    chosenListCategory(state) {
      return state.chosenListCategory;
    },
    updateEvent(state) {
      return state.updateEvent;
    },
    eventRewards(state) {
      return state.eventRewards;
    },
    updateEventRewards(state) {
      return state.updateEventRewards;
    },
    chosenEventType(state) {
      return state.chosenEventType;
    },
    updateChosenEventType(state) {
      return state.updateChosenEventType;
    },
    eventTypesName(state) {
      return state.eventTypesName;
    },
    eventTypesIndex(state) {
      return state.eventTypesIndex;
    },
    eventTypesIcons(state) {
      return state.eventTypesIcons;
    },
  },
  mutations: {
    setEvents(state, payload) {
      state.events = payload;
    },
    setEvent(state, payload) {
      state.event = payload;
      state.eventRewards = [];
      if (state.event.rewards === undefined)
        return;
      state.updateChosenEventType = state.eventTypesName[payload.type];

      if (state.event.rewards.length < 5) {
        state.eventRewards = [];
        return;
      }
      state.event.rewards.split('|').forEach((element) => {
        state.eventRewards.push({ id: element.split(',')[0], points: element.split(',')[1] })
      });
    },
    setChosenListCategory(state, payload) {
      state.chosenListCategory = payload;
    },
    setFilterEvents(state, payload) {
      state.filterEvents = payload;
    },
    addEventRewards(state, payload) {
      state.eventRewards.push(payload);
    },
    addUpdateEventRewards(state, payload) {
      state.updateEventRewards.push(payload);
    },
    removeEventRewards(state, payload) {
      const { index } = payload;
      state.eventRewards.splice(index, 1);
    },
    removeUpdateEventRewards(state, payload) {
      const { index } = payload;
      state.updateEventRewards.splice(index, 1);
    },
    setUpdateEvent(state, payload) {
      state.updateEvent = payload;
      state.updateEventRewards = [];
      if (!state.updateEvent.rewards || state.updateEvent.rewards === undefined)
        return;
      state.updateChosenEventType = state.eventTypesName[payload.type];

      if (state.updateEvent.rewards.length < 5) {
        state.updateEventRewards = [];
        return;
      }
      state.updateEvent.rewards.split('|').forEach((element) => {
        state.updateEventRewards.push({ id: element.split(',')[0], points: element.split(',')[1] })
      });
    },
    setChosenEventType(state, name) {
      state.chosenEventType = name;
      state.event.type = state.eventTypesIndex[name];
    },
    setUpdateChosenEventType(state, name) {
      state.updateChosenEventType = name;
      state.updateEvent.type = state.eventTypesIndex[name];
    },
  },
  actions: {
    setEvent({ commit }, payload) {
      commit('setEvent', payload);
    },
    setChosenListCategory({ commit }, payload) {
      commit('setChosenListCategory', payload);
    },
    setFilterEvents({ commit }, payload) {
      commit('setFilterEvents', payload);
    },
    addEventRewards({ commit }, payload) {
      commit('addEventRewards', payload);
    },
    addUpdateEventRewards({ commit }, payload) {
      commit('addUpdateEventRewards', payload);
    },
    removeEventRewards({ commit }, payload) {
      commit('removeEventRewards', payload);
    },
    removeUpdateEventRewards({ commit }, payload) {
      commit('removeUpdateEventRewards', payload);
    },
    setUpdateEvent({ commit }, payload) {
      commit('setUpdateEvent', payload);
    },
    setChosenEventType({ commit }, payload) {
      commit('setChosenEventType', payload);
    },
    setUpdateChosenEventType({ commit }, payload) {
      commit('setUpdateChosenEventType', payload);
    },
    async getEventRequest({ commit }) {
      axios
        .get('events/get')
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setEvents", res.data.data);
          } else {
            commit("logOut");
            commit('setLogged', false);
          }
        })
        .catch(() => {
          commit("logOut");
          commit('setLogged', false);
        });
    },
    async cloneEventRequest({ commit, state }, payload) {
      const { callback, newId } = payload;
      await axios
        .post('events/clone/' + state.updateEvent.id + '/' + newId)
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setEvents", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Clonata missione ID: " + state.updateEvent.id);
            commit('setSnackbar', true);
            callback();
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
        });
    },
    async updateEventRequest({ commit, state }, callback) {
      const e = state.updateEvent;
      e.range = state.eventTypesIndex[state.updateChosenEventType];
      state.updateEvent.rewards = '';
      state.updateEventRewards.forEach((element, index) => {
        state.updateEvent.rewards += element.id + "," + element.points + (index < state.updateEventRewards.length - 1 ? "|" : "");
      });
      await axios
        .put('events/update/' + e.id, e)
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setEvents", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Modificato event ID: " + e.id);
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
          callback();
        });
    },
    async createEventRequest({ commit, state }, callback) {
      const e = state.event;
      e.range = state.eventTypesIndex[state.chosenEventType];
      state.eventRewards.forEach((element, index) => {
        state.event.rewards += element.id + "," + element.points + (index < state.eventRewards.length - 1 ? "|" : "");
      });
      await axios
        .post('events/create/', e)
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setEvents", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Creato nuova evento");
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
          callback();
        });
    },
    async deleteEventRequest({ commit, state }, callback) {
      const e = state.updateEvent;
      await axios
        .delete('events/delete/' + e.id)
        .then((res) => {
          if (res != undefined && res.data.status) {
            commit("setEvents", res.data.data);
            commit('setResponse', true);
            commit('setSnackbarText', "Eliminato evento ID: " + e.id);
            commit('setSnackbar', true);
          } else {
            commit('setResponse', false);
            commit('setSnackbarText', res.data.data);
            commit('setSnackbar', true);
          }
          callback();
        }).catch((error) => {
          commit('setResponse', false);
          commit('setSnackbarText', error.response.data.data ?? error);
          commit('setSnackbar', true);
          callback();
        });
    }
  },
};

export default eventModule;
