<template>
    <v-card class="event-card" :style="{ backgroundColor: currentProduct.active ? '#b4f36d' : '#f77070' }">
        <v-card-text class="flex row align-items">
            <div class="flex column h-margin10 gap20">
                <v-select :value="chosenProductType" solo :items="productTypes" v-on:change="setType">
                </v-select>
                <v-icon class="special-icon" large>
                    {{ productTypeIcons[productTypeIndex[chosenProductType]] }}
                </v-icon>
                <div v-if="chosenProductType === 'A livello'" class="event-row">
                    <strong>Livello:</strong> <input v-model="currentProduct.unlock_level" />
                </div>
                <v-select :value="chosenProductCurrency" solo :items="productCurrency"
                    v-on:change="setCurrency"></v-select>
                <v-icon class="special-icon" large>
                    {{ productCurrencyIcons[productCurrencyIndex[chosenProductCurrency]] }}
                </v-icon>
                <div v-if="(chosenProductCurrency !== 'Euro' && chosenProductCurrency !== 'Gratis')" class="event-row">
                    <strong>Quantità:</strong> <input v-model="currentProduct.amount" />
                </div>
                <div class="event-row">
                    <strong>ID:</strong> <input v-model="currentProduct.id" />
                </div>
                <div class="flex column">
                    <input type="checkbox" class="checkbox" v-model="currentProduct.active" />
                    <strong style="margin: auto;">{{ currentProduct.active ? 'Attivo' : 'Disattivo' }}</strong>
                </div>
                <div class="event-row">
                    <strong>Licenza PRO: (ID scenario)</strong> <input v-model="currentProduct.licence" />
                </div>

                <div class="flex column">

                    <div class="flex row" style="align-items: center;">
                        <strong>Notifiche:</strong>
                        <input type="checkbox" class="checkbox" v-model="currentProduct.notify" />
                    </div>
                    <span class="flex row">
                        <v-icon large style="color: rgba(0,0,0,0.6); margin: auto;"
                            v-if="currentProduct.notify">mdi-bell</v-icon>
                        <v-icon large style="color: rgba(0,0,0,0.6); margin: auto;" v-else>mdi-bell-off</v-icon>
                    </span>
                </div>
            </div>
            <div class="flex column h-margin10 space-around">
                <div class="event-row">
                    <strong>SKU:</strong> <input v-model="currentProduct.sku" />
                </div>

                <div class="event-row">
                    <strong>ID row:</strong> <input v-model="currentProduct.row_id" />
                </div>

                <div class="event-row">
                    <strong>Disponibile da:</strong>
                    <v-dialog v-model="initDialog" max-width="700">
                        <template v-slot:activator="{ props: activatorProps }">
                            <v-btn v-bind="activatorProps" v-on:click="initDialog = !initDialog">
                                {{ currentProduct.available_from }}
                            </v-btn>
                        </template>
                        <DateTimePicker @confirmed-date="confirmDate" @cancel-date="cancelDate" tag="init"
                            :initialDate="currentProduct.available_from" />
                    </v-dialog>
                </div>

                <div class="event-row">
                    <strong>Disponibile fino a:</strong>
                    <v-dialog v-model="endDialog" max-width="700">
                        <template v-slot:activator="{ props: activatorProps }">
                            <v-btn v-bind="activatorProps" v-on:click="endDialog = !endDialog">
                                {{ currentProduct.expire_at }}
                            </v-btn>
                        </template>
                        <DateTimePicker @confirmed-date="confirmDate" @cancel-date="cancelDate" tag="end"
                            :initialDate="currentProduct.expire_at" />
                    </v-dialog>
                </div>
                <div class="event-row">
                    <strong>Disponibile in secondi:</strong> <input v-model="currentProduct.seconds_to_open" />
                </div>

                <div class="event-row">
                    <strong>Pacchetti:</strong> <input v-model="currentProduct.pack_id" />
                </div>

                <div class="event-row">
                    <strong>Uccelli:</strong> <input v-model="currentProduct.birds" />
                </div>
                <div class="event-row">
                    <strong>Avatar:</strong> <input v-model="currentProduct.avatar" />
                </div>
                <div class="event-row">
                    <strong>Bordo Avatar:</strong> <input v-model="currentProduct.border" />
                </div>

                <div class="event-row">
                    <strong>Sfondo Carta:</strong> <input v-model="currentProduct.card" />
                </div>

                <div class="event-row">
                    <strong>Emoji:</strong> <input v-model="currentProduct.emoji" />
                </div>

            </div>
            <div class="flex column h-margin10 space-around">
                <div class="event-row">
                    <strong>Miglior foto:</strong> <input v-model="currentProduct.better_photo" />
                </div>
                <div class="event-row">
                    <strong>Bird radar:</strong> <input v-model="currentProduct.bird_radar" />
                </div>
                <div class="event-row">
                    <strong>Doppia foto:</strong> <input v-model="currentProduct.double_photo" />
                </div>
                <div class="event-row">
                    <strong>Esposizione perfetta:</strong> <input v-model="currentProduct.perfect_exposure" />
                </div>
                <div class="event-row">
                    <strong>Piume:</strong> <input v-model="currentProduct.feathers" />
                </div>
                <div class="event-row">
                    <strong>Focus rapido:</strong> <input v-model="currentProduct.rapid_focus" />
                </div>
                <div class="event-row">
                    <strong>Probabilità:</strong> <input v-model="currentProduct.probability" />
                </div>
                <div class="event-row">
                    <strong>Monete:</strong> <input v-model="currentProduct.coins" />
                </div>
                <div class="event-row">
                    <strong>Monete speciali:</strong> <input v-model="currentProduct.special_coins" />
                </div>
                <div class="event-row">
                    <strong>Fortuna:</strong> <input v-model="currentProduct.luck" />
                </div>
                <div class="event-row">
                    <strong>Punti VIP:</strong> <input v-model="currentProduct.vip_points" />
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>
<script>

import store from '@/store/index.js'
import DateTimePicker from '@/components/DateTimePicker.vue';
export default {
    name: "ProductCard",
    data() {
        return {
            initDialog: false,
            endDialog: false
        }
    },
    props: {
        new: Boolean
    },
    components: { DateTimePicker },
    methods: {
        cancelDate() {
            this.initDialog = false;
            this.endDialog = false;
        },
        confirmDate(data) {
            const { tag, date } = data;
            switch (tag) {
                case 'init':
                    this.currentProduct.available_from = date;
                    break;
                case 'end':
                    this.currentProduct.expire_at = date;
                    break;
                default:
                    break;
            }
            this.initDialog = false;
            this.endDialog = false;
        },
        setType(value) {
            if (this.new)
                store.dispatch('setChosenProductType', value);
            else
                store.dispatch('setUpdateChosenProductType', value);
        },
        setCurrency(value) {
            if (this.new)
                store.dispatch('setChosenProductCurrency', value);
            else
                store.dispatch('setUpdateChosenProductCurrency', value);
        },
    },
    computed: {
        currentProduct() {
            return this.new ? store.getters.product : store.getters.updateProduct;
        },
        chosenProductCurrency() {
            return this.new ? store.getters.chosenProductCurrency : store.getters.updateChosenProductCurrency;
        },
        chosenProductType() {
            return this.new ? store.getters.chosenProductType : store.getters.updateChosenProductType;
        },
        productTypes() {
            var types = [];
            store.getters.productTypes.forEach(element => {
                types.push(element.name);
            });
            return types;
        },
        productTypeIcons() {
            var icons = [];
            store.getters.productTypes.forEach(element => {
                icons.push(element.icon);
            });
            return icons;
        },
        productCurrency() {
            var types = [];
            store.getters.productCurrency.forEach(element => {
                types.push(element.name);
            });
            return types;
        },
        productCurrencyIcons() {
            var icons = [];
            store.getters.productCurrency.forEach(element => {
                icons.push(element.icon);
            });
            return icons;
        },
        productTypeIndex() {

            return store.getters.productTypesIndex;
        },
        productCurrencyIndex() {
            return store.getters.productCurrencyIndex;
        },
    }
}
</script>