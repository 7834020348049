<template>
	<div class="questions-body">
		<section class="quest-column">
			<header class="questions-title-icon">
				<img :src=eventImage width="40" alt="Domande" />
				<h1>Domande</h1>
				<v-spacer></v-spacer>
				<input type="checkbox" id="fatti-check" name="fatti" v-on:click="filterList" />
			</header>
			<v-card v-for="( question, index ) in filtered_questions" :key="index" class="flex row event-card"
				:style="{ backgroundColor: question.done ? '#b4f36d' : '#f77070', borderColor: '#222222' }"
				@click="vai(index)">
				<v-card-text>
					<div class="question-card-content gap20">
						<div class="flex row space-around">
							<div class="align-start">
								<p><strong>Email:</strong></p>
								<p>
									{{ question.email ?? 'N.F.' }}
								</p>
							</div>
							<v-spacer></v-spacer>
							<div class="align-start">
								<p><strong>Dispositivo:</strong></p>
								<p>
									{{ question.description ? question.description.split('|')[0] : 'N.F.' }}
								</p>
							</div>
							<v-spacer></v-spacer>
							<div class="align-start">
								<p><strong>Dispositivo:</strong></p>
								<p>
									{{ question.updated_at.substring(0, 10) }}
								</p>
							</div>
						</div>
						<div class="align-start">
							<p><strong>Domanda:</strong></p>
							<p>
								{{ question.question }}
							</p>
						</div>
						<div class="align-start">
							<p><strong>Descrizione:</strong></p>
							<p>
								{{ question.description ? question.description.split('|')[1] : 'N.F.' }}
							</p>
						</div>
					</div>
				</v-card-text>
			</v-card>
		</section>
		<section id="update_event" class="quest-column">
			<header>
				<h1>Modifica o elimina Domanda</h1>
			</header>
			<v-card class="flex event-card"
				:style="{ backgroundColor: update_question.done ? '#b4f36d' : '#f77070', borderColor: '#222222' }"
				@click="vai(index)">
				<v-card-text>
					<div class="question-card-content gap20">
						<div class="flex row space-around">
							<div class="align-start">
								<p><strong>Email:</strong></p>
								<p>
									{{ update_question.email ?? 'N.F.' }}
								</p>
							</div>
							<v-spacer></v-spacer>
							<div class="align-start">
								<p><strong>Dispositivo:</strong></p>
								<p>
									{{ update_question.description ? update_question.description.split('|')[0] : 'N.F.' }}
								</p>
							</div>
						</div>
						<div class="align-start">
							<p><strong>Domanda:</strong></p>
							<p>
								{{ update_question.question }}
							</p>
						</div>
						<div class="align-start">
							<p><strong>Descrizione:</strong></p>
							<p>
								{{ update_question.description ? update_question.description.split('|')[1] : 'N.F.' }}
							</p>
						</div>
					</div>
				</v-card-text>
			</v-card>
			<button class="new-event-btn" v-on:click="undo()">ANNULLA</button>
			<button class="new-event-btn" v-on:click="updatequestion(update_question.id)">FATTO/DA FARE</button>
			<button class="new-event-btn" v-on:click="deletequestion(update_question.id)">ELIMINA</button>
		</section>
	</div>
</template>


<script>
import axios from 'axios';
import store from '@/store/index.js'
import { getQuestions } from '../functions.js';
import eventImage from '@/assets/images/Icon_events.png';

export default {
	name: 'questionsView',
	data: () => ({
		model: null,
		question: {
			"id": 0,
			"email": '',
			"question": '',
			"description": '',
			'done': false
		},
		update_question: {}
	}),
	computed: {
		token() {
			return store.getters.token;
		},
		questions() {
			return store.getters.questions;
		},
		filtered_questions() {
			return store.getters.filter_questions;
		},
		eventImage() {
			return eventImage;
		}
	},
	methods: {
		filterList() {
			if (document.getElementById("fatti-check").checked) {
				store.dispatch('setFilterQuestions', this.questions);
			} else {
				store.dispatch('setFilterQuestions', this.questions.filter(question =>
				(
					!question.done
				)
				));
			}
		},
		vai(index) {
			this.update_question = Object.assign({}, this.questions[index]);
			document.getElementById('update_event').scrollIntoView();
		},
		undo() {
			this.update_question = {};
		},
		async updatequestion() {
			this.update_question.done = !this.update_question.done;
			await axios
				.put('questions/update/' + this.update_question.id, this.update_question)
				.then(() => {
					this.dialog = false;
					getQuestions();
				});
		},
		async deletequestion() {
			await axios
				.delete('questions/delete/' + this.update_question.id)
				.then((res) => {
					if (res != undefined && res.data.status)
						getQuestions();
				});
		}
	},
	async mounted() {
		if (this.token === null)
			store.dispatch('setIndex', 0);
		else
			await getQuestions();
		document.getElementById("fatti-check").checked = true;
	}
}
</script>


<style>
.questions-body {
	display: flex;
	flex-direction: column;
	margin: 20px auto;
	gap: 10px;
	width: 100%;
	max-width: 700px;
	overflow-x: scroll;
}

.questions-body h1 {
	font-family: 'Cairo';
	font-weight: 900;
	color: bisque;
	text-align: flex-start;
}

.icon {
	position: relative;
	display: flex;
	flex-direction: column;
	height: 200px;
	width: 300px;
	justify-content: space-between;
}

.v-select {
	height: 50px;
	width: 200px;
}

.v-select input {
	background-color: transparent;
	width: 0px;
}

.question-card-content {
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 1000px;
}

.event-icon {
	display: flex;
	flex-direction: column;
	margin: 10px;
	min-width: 60px;
}

.new-event-card {
	margin: 5px;
	border: solid 3px;
	align-items: center;
	justify-content: space-around;
}

.new-event-card .row {
	margin: 10px;
}

.new-event-card strong {
	margin-right: 13px;
}

.event-card .row {
	margin: 10px;
}

.event-card strong {
	margin-right: 13px;
}

.questions-title-icon {
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: 1rem;
}

.questions-title-icon h1 {
	width: fit-content;
}

.questions-title-icon input {
	margin: 0;
	padding: 0;
	width: clamp(20px, 3vw, 30px);
}


.table-header {
	background-color: antiquewhite;
	box-shadow: 0 3px 5px black;
}

.table-header th {
	padding: 2px;
}

.header-row {
	display: flex;
	flex-direction: row;
}

.div-tile {
	display: flex;
	flex-direction: row;
	margin: 2px;
}

.table-tile {
	background-color: cadetblue;
}

.new-event-btn {
	width: 150px;
	height: 50px;
	background-color: antiquewhite;
	border-radius: 20px;
	border: 2px solid black;
}

@media screen and (max-width: 780px) {
	.question-card-content {
		width: 100%;
	}
}
</style>