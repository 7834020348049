<template>
	<div>
		<section v-if="checkpoints.length < limit" class="fwr-column">
			<header>
				<h1>Aggiungi checkpoint</h1>
			</header>
			<v-card class="new-product-card" :style="{ backgroundColor: '#b4f36d', borderColor: '#222222' }">
				<v-card-text class="flex row">
					<div class="flex column">
						<ul class="event-list">
							<li>
								<div class="flex row">
									<strong>Spins:</strong> <v-spacer></v-spacer> <input v-model="check.name" />
								</div>
							</li>
						</ul>
					</div>
				</v-card-text>
			</v-card>

			<div class="row  v-margin10 space-around">
				<button class="new-event-btn" v-on:click="createFW">CONFERMA</button>
			</div>
		</section>

		<div class="flex row">
			<section class="fwr-column">
				<header class="title-icon">
				<v-icon large >mdi-check-decagram</v-icon>
					<h1>Checkpoints Ruota fortuna</h1>
				</header>
				<v-card v-for="( check, index ) in checkpoints" :key="index" class="flex row fwr-card"
					:style="{ backgroundColor: '#b4f36d', borderColor: '#222222' }" @click="vai(index)">
					<v-card-text class="flex column">
						<span>
							<strong>Spins:</strong> {{ check.name }}
						</span>
						<span>
							<strong>Reward ID:</strong> {{ check.reward }}
						</span>
					</v-card-text>
				</v-card>
			</section>
			<section v-if="editCheck" class="fwr-column">
				<header>
					<h1>Elimina checkpoint</h1>
				</header>
				<v-card class="new-product-card" :style="{ backgroundColor: '#b4f36d', borderColor: '#222222' }">
					<v-card-text class="flex column">
						<div class="flex row">
							<strong>Spins:</strong> <v-spacer></v-spacer> {{ update_check.name }}
						</div>
						<div class="flex row">
							<strong>Reward ID:</strong> <v-spacer></v-spacer> {{ update_check.reward }}
						</div>
					</v-card-text>
				</v-card>
				<div class="row space-around">
					<button class="new-event-btn v-margin10 h-margin20" v-on:click="undo()">ANNULLA</button>
					<button class="new-event-btn v-margin10 h-margin20" v-on:click="deleteFW()">ELIMINA</button>
				</div>
			</section>
		</div>
	</div>
</template>


<script>
import axios from 'axios';
import store from '@/store/index.js'
import { getFW, getCheckpoints } from '@/functions'


export default {
	name: 'FWRewardView',
	data: () => ({
		check: {
			"name": 0,
			"reward": 0,
		},
		shopView: false,
		chosen_checkpoint: "",
		chosen_rarity: "",
		update_chosen_rarity: "",
		update_check: {},
		search: false,
		snackbar: false,
		snackbar_text: '',
		quantita: [15, 30, 50, 100],
		quantity: 15,
		page: 1,
		pages: 0,
		response: false,
		editCheck: false,
	}),
	computed: {
		token() {
			return store.getters.token;
		},
		FW() {
			return store.getters.FW;
		},
		checkpoint() {
			var ch = [];

			for (var i in store.getters.checkpoint) {
				ch.push(i);
			}
			return ch;
		},
		rarities_name() {
			var ch = [];
			for (var i in store.getters.rarities) {
				ch.push(store.getters.rarities[i]['name']);
			}
			return ch;
		},
		checkpoints() {
			var ch = [];

			for (var i in store.getters.checkpoint) {
				var tr = {};
				tr.name = i;
				tr.reward = store.getters.checkpoint[i];
				ch.push(tr);
			}
			return ch;
		},
		current_page() {
			return this.page;
		},
		limit() {
			return store.getters.checkpoint_limit;
		}
	},
	methods: {
		rarities_index(name) {
			for (var i in store.getters.rarities) {
				if (store.getters.rarities[i]['name'] === name)
					return i;
			}
			return null;
		},
		changePage(num) {
			this.page = num;
		},
		vai(index) {
			this.update_check = Object.assign({}, this.checkpoints[index]);
			this.editCheck = true;
		},
		undo() {
			this.update_check = {};
			this.editCheck = false;
		},
		async createFW() {
			await axios
				.post('fortune/wheel/checkpoint/create/', { 'checkpoint': this.check.name })
				.then((res) => {
					if (res != undefined && res.data.status) {
						store.dispatch("setCheckpoint", res.data.data);
						this.response = true;
						this.snackbar_text = "Aggiunto checkpoint: " + this.check.name;
						this.snackbar = true;
						setTimeout(() => {
							this.snackbar = false;
						}, 2500);
					} else {
						this.response = false;
						this.snackbar_text = res.data.data;
						this.snackbar = true;
					}
				}).catch((error) => {
					this.response = false;
					this.snackbar_text = error.response.data.data;
					this.snackbar = true;
				});
		},
		async deleteFW() {
			if (this.update_check.name === undefined)
				return;
			await axios
				.delete('fortune/wheel/checkpoint/delete/?checkpoint=' + this.update_check.name)
				.then((res) => {
					if (res != undefined && res.data.status) {
						store.dispatch("setCheckpoint", res.data.data);
						this.response = true;
						this.snackbar_text = "Eliminato checkpoint: " + this.update_check.name;
						this.snackbar = true;
						setTimeout(() => {
							this.snackbar = false;
						}, 2500);
					} else {
						this.response = false;
						this.snackbar_text = res.data.data;
						this.snackbar = true;
					}
				}).catch((error) => {
					this.response = false;
					this.snackbar_text = error.response.data.data;
					this.snackbar = true;
				});
		},
	},
	async mounted() {
		getFW();
		getCheckpoints();
	}
}
</script>


<style>
.filter-bar {
	height: fit-content;
	padding: 10px;
	background-color: var(--secondary-color);
	border-radius: 15px;
	color: white;
}

.side-legend {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 100px;
	right: 0;
	width: 300px;
	border-radius: 17px;
	margin: 5px;
	padding: 10px;
	background-color: #b2ff9f;
}

.search-tools {
	position: absolute;
	display: flex;
	flex-direction: column;
	background-color: var(--secondary-color);
	margin: 20px;
	padding: 10px;
	border-radius: 20px;
	border: 2px solid black;
	right: 0;
}

.pages-index {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
}

.pages-index span {
	font-family: 'Fredoka';
	font-weight: 600;
	color: #faebd7;
	cursor: pointer;
	min-width: 24px;
}


.search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.search-row .v-input {
	max-width: 90px;
}

.search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}

.cat-search-row {
	position: relative;
	display: flex;
	flex-direction: row;
	margin: 10px;
	justify-content: space-around;
	align-items: center;
}

.cat-search-row .v-input__control {
	font-family: 'Fredoka';
	font-weight: 600;
	max-height: 48px;
}

.cat-search-row p {
	padding: 0;
	margin: 0;
	font-family: 'Fredoka';
	font-weight: 600;
	color: antiquewhite;
}


.fwr-body {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	margin-top: 110px;
	gap: 10px;
	overflow-x: scroll;
}

.fwr-column {
	border-radius: 20px;
	box-shadow: 0 5px 15px rgb(86, 193, 5);
	margin-top: 20px;
	margin-left: 10px;
	margin-right: 10px;
	margin-bottom: 20px;
	padding: 10px;
}

.fwr-body h1 {
	font-family: 'Cairo';
	font-weight: 900;
	color: #ffe4c4;
	text-align: flex-start;
}

.fwr-icon {
	display: flex;
	flex-direction: column;
	margin: 10px;
	min-width: 60px;
}

.new-fwr-icon {
	display: flex;
	flex-direction: column;
	max-width: 250px;
}

.fwr-card {
	margin: 5px;
	border: solid 3px;
	align-items: center;
}

.fwr-card .row {
	margin: 10px;
}

.fwr-card strong {
	margin-right: 13px;
}

.fwr-card input {
	max-width: 65px;
	min-width: 50px;
}

.fwr-body input {
	min-height: 30px;
	margin: 0 auto;
}

.title-icon {
	display: flex;
	flex-direction: row;
	gap: 20px;
	margin: 1rem;
}

.title-icon h1 {
	width: fit-content;
}


.table-header {
	background-color: antiquewhite;
	box-shadow: 0 3px 5px black;
}

.table-header th {
	padding: 2px;
}

.header-row {
	display: flex;
	flex-direction: row;
}

.div-tile {
	display: flex;
	flex-direction: row;
	margin: 2px;
}

.table-tile {
	background-color: cadetblue;
}

.new-event-btn {
	width: 150px;
	height: 50px;
	background-color: antiquewhite;
	border-radius: 20px;
	border: 2px solid black;
}
</style>